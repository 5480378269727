import { createAsyncThunk } from "@reduxjs/toolkit";
import officeApi from "../../services/office";
import { GET_DASHBOARD_OFFICE } from "./type";

const getDashboardOfficeAsync = async (params, { dispatch }) => {
  const result = await officeApi.getDashboardOffice(params, dispatch);
  const dashboardDataList = result.code === "0" ? result.data : {};
  if (dashboardDataList.dataList) {
    dashboardDataList.dataList.forEach((dashboard, index) => {
      dashboard["key"] = index;
      return dashboard;
    });
  }
  return dashboardDataList;
};

//action creator
export const getDashboardOffice = createAsyncThunk(
  GET_DASHBOARD_OFFICE,
  getDashboardOfficeAsync
);
