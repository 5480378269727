import { AuthenticationContext } from "react-adal";

export const adalContext = {
  tenant: "adfs",
  clientId: process.env.REACT_APP_CLIENT_ID,
  redirectUri: process.env.REACT_APP_CLIENT_ENDPOINT,
  postLogoutRedirectUri: process.env.REACT_APP_CLIENT_ENDPOINT,
  instance: process.env.REACT_APP_AD_URL,
  endpoints: {
    api: process.env.REACT_APP_API_URL,
  },
  cacheLocation: "localStorage",
};

export const authContext = new AuthenticationContext(adalContext);

export const getToken = () =>
  authContext.getCachedToken(process.env.REACT_APP_CLIENT_ID);

export const getUser = () => authContext.getCachedUser();

export const logOut = () => authContext.logOut();

export const getLogOutUrl = () => authContext.getLogOutUrl();

export const clearCache = () => authContext.clearCache();
