import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import apiClient from "../../services/api";
import { loadingPage } from "../../loading";

import * as TYPES from "./type";

const getHighchartsDataAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/dashboard/getHighchartsData",
      params.data
    );
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }

      if (response.data) {
        let responseData = { ...response.data };
        responseData.data = { ...responseData.data, isSearch: params.isSearch };
        return responseData;
      } else {
        return { data: [] };
      }
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getDeviceDataRealtimeAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/dashboard/getDeviceDataRealtime",
      params.data
    );
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getDeviceDataRealtimeTableAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/dashboard/getDeviceDataRealtimeTable",
      params.data
    );
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const uploadOperationGuidanceManualFileAsync = async (
  params = {},
  { dispatch }
) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }

    const formData = new FormData();
    //  formData.append("factoryId", params.data.factoryId);
    formData.append("processPlaceId", params.data.processPlaceId);
    formData.append("file", params.data.file);

    const response = await apiClient.post(
      "/dashboard/uploadOperationGuidanceManualFile",
      formData
    );
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const deleteOperationGuidanceManualFileAsync = async (
  params = {},
  { dispatch }
) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }

    const response = await apiClient.post(
      "/dashboard/deleteOperationGuidanceManualFile",
      params.data
    );
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

export const getDeviceDataRealtime = createAsyncThunk(
  TYPES.GET_DASHBOARD_DEVICE_DATA_REALTIME,
  getDeviceDataRealtimeAsync
);

export const getDeviceDataRealtimeTable = createAsyncThunk(
  TYPES.GET_DASHBOARD_DEVICE_DATA_REALTIME_TABLE,
  getDeviceDataRealtimeTableAsync
);

export const getHighchartsData = createAsyncThunk(
  TYPES.GET_DASHBOARD_HIGHCHART_DATA,
  getHighchartsDataAsync
);

export const uploadOperationGuidanceManualFile = createAsyncThunk(
  TYPES.UPLOAD_OPERATION_GUIDANCE_MANUAL_FILE,
  uploadOperationGuidanceManualFileAsync
);

export const deleteOperationGuidanceManualFile = createAsyncThunk(
  TYPES.DELETE_OPERATION_GUIDANCE_MANUAL_FILE,
  deleteOperationGuidanceManualFileAsync
);
