export const SYSTEM_WARNING_DETAIL_NOTYFYS = [
  "MATLAB_GETDATA_ERROR_TYPE",
  "WEATHER_GETDATA_ERROR_TYPE",
  "MATLAB_SENDDATA_ERROR_TYPE",
  "INPUT_DATA_ERROR_TYPE",
  "ACCURACY_ERROR_TYPE",
  "SYSTEM_ERROR_TYPE",
];

export const WARNING_HISTORY_DETAIL_NOTYFYS = [
  "INFLOW_WATER_ERROR_TYPE",
  "INFLOW_WATER_REAL_ERROR_TYPE",
  "WATER_LEVEL_ERROR_TYPE",
  "EVENT_STATUS_CHANGE_TYPE",
  "RAIN_FALL_PREDICT_ERROR_TYPE",
];
