import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import apiClient from "../../services/api";
import { loadingPage } from "../../loading";

import * as TYPES from "./type";
import {GET_OPERATION_SUPPORT_GUIDANCE, GET_OPERATION_SUPPORT_GUIDANCE_TYPE} from "./type";

const formatDefaultWCs = (list) => {
  if (list && list.data) {
    let config = {
      warnOperator: ">",
      warnType: 2,
      status: 1,
    };
    list.data.forEach((item) => {
      if (item.code === "WARN_TYPE") config.warnType = item.value;
      else if (item.code === "WARN_OPERATOR1") config.warnOperator1 = item.value;
      else if (item.code === "WARN_OPERATOR2") config.warnOperator2 = item.value;
      else if (item.code === "WARN_OPERATOR3") config.warnOperator3 = item.value;
      else if (item.code === "WARN_OPERATOR4") config.warnOperator4 = item.value;
      else if (item.code === "WARN_LABEL1") config.warnLabel1 = item.value;
      else if (item.code === "WARN_ENABLE1")
        config.warnEnable1 = Number(item.value);
      else if (item.code === "WARN_THRESHOLD1")
        config.warnThreshold1 = item.value;
      else if (item.code === "WARN_EXCEEDED_ENABLE1")
        config.warnExceededEnable1 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_THRESHOLD1")
        config.warnExceededThreshold1 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_UNIT1")
        config.warnExceededUnit1 = Number(item.value);
      else if (item.code === "WARN_RETRY_ENABLE1")
        config.warnRetryEnable1 = Number(item.value);
      else if (item.code === "WARN_RETRY_THRESHOLD1")
        config.warnRetryThreshold1 = Number(item.value);
      else if (item.code === "WARN_RETRY_UNIT1")
        config.warnRetryUnit1 = Number(item.value);
      else if (item.code === "WARN_LABEL2") config.warnLabel2 = item.value;
      else if (item.code === "WARN_ENABLE2")
        config.warnEnable2 = Number(item.value);
      else if (item.code === "WARN_THRESHOLD2")
        config.warnThreshold2 = item.value;
      else if (item.code === "WARN_EXCEEDED_ENABLE2")
        config.warnExceededEnable2 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_THRESHOLD2")
        config.warnExceededThreshold2 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_UNIT2")
        config.warnExceededUnit2 = Number(item.value);
      else if (item.code === "WARN_RETRY_ENABLE2")
        config.warnRetryEnable2 = Number(item.value);
      else if (item.code === "WARN_RETRY_THRESHOLD2")
        config.warnRetryThreshold2 = Number(item.value);
      else if (item.code === "WARN_RETRY_UNIT2")
        config.warnRetryUnit2 = Number(item.value);
      else if (item.code === "WARN_LABEL3") config.warnLabel3 = item.value;
      else if (item.code === "WARN_ENABLE3")
        config.warnEnable3 = Number(item.value);
      else if (item.code === "WARN_THRESHOLD3")
        config.warnThreshold3 = item.value;
      else if (item.code === "WARN_EXCEEDED_ENABLE3")
        config.warnExceededEnable3 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_THRESHOLD3")
        config.warnExceededThreshold3 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_UNIT3")
        config.warnExceededUnit3 = Number(item.value);
      else if (item.code === "WARN_RETRY_ENABLE3")
        config.warnRetryEnable3 = Number(item.value);
      else if (item.code === "WARN_RETRY_THRESHOLD3")
        config.warnRetryThreshold3 = item.value;
      else if (item.code === "WARN_RETRY_UNIT3")
        config.warnRetryUnit3 = Number(item.value);
      else if (item.code === "WARN_LABEL4") config.warnLabel4 = item.value;
      else if (item.code === "WARN_ENABLE4")
        config.warnEnable4 = Number(item.value);
      else if (item.code === "WARN_THRESHOLD4")
        config.warnThreshold4 = item.value;
      else if (item.code === "WARN_EXCEEDED_ENABLE4")
        config.warnExceededEnable4 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_THRESHOLD4")
        config.warnExceededThreshold4 = Number(item.value);
      else if (item.code === "WARN_EXCEEDED_UNIT4")
        config.warnExceededUnit4 = Number(item.value);
      else if (item.code === "WARN_RETRY_ENABLE4")
        config.warnRetryEnable4 = Number(item.value);
      else if (item.code === "WARN_RETRY_THRESHOLD4")
        config.warnRetryThreshold4 = Number(item.value);
      else if (item.code === "WARN_RETRY_UNIT4")
        config.warnRetryUnit4 = Number(item.value);
      else if (item.code === "WARN_OPERATION_SUPPORT_GUIDANCE1")
        config.warnOperationSupportGuidance1 = item.value;
      else if (item.code === "WARN_OPERATION_SUPPORT_GUIDANCE2")
        config.warnOperationSupportGuidance2 = item.value;
      else if (item.code === "WARN_OPERATION_SUPPORT_GUIDANCE3")
        config.warnOperationSupportGuidance3 = item.value;
      else if (item.code === "WARN_OPERATION_SUPPORT_GUIDANCE4")
        config.warnOperationSupportGuidance4 = item.value;
    });
    return config;
  }
  return {};
};

const getLandingPagesAsync = async (params = {}, { dispatch }) => {
  if (!params.noLoading) {
    dispatch(loadingPage(true));
  }
  try {
    const response = await apiClient.post("/systemConfig/getListConfigKeyVal", {
      type: "LANDING_PAGE_TYPE",
    });
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      return response.data ? response.data : { data: [] };
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getOperatorWarningConfigsAsync = async (params = {}, { dispatch }) => {
  if (!params.noLoading) {
    dispatch(loadingPage(true));
  }
  try {
    const response = await apiClient.post("/systemConfig/getListConfigKeyVal", {
      type: "WARN_OPERATOR_TYPE",
    });
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      return response.data ? response.data : { data: [] };
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getOperationSupportGuidanceListAsync = async (params = {}, { dispatch }) => {
  if (!params.noLoading) {
    dispatch(loadingPage(true));
  }
  try {
    const response = await apiClient.post("/systemConfig/getListConfigKeyVal", {
      type: "OPERATION_SUPPORT_GUIDANCE_TYPE",
      code: "OPERATION_SUPPORT_GUIDANCE_CODE",
    });
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      return response.data ? response.data : { data: [] };
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getDefaultWarningConfigsAsync = async (params = {}, { dispatch }) => {
  if (!params.noLoading) {
    dispatch(loadingPage(true));
  }
  try {
    const response = await apiClient.post("/systemConfig/getListConfig", {
      type: "WARN_CONFIG_DEFAULT_VAL_TYPE",
    });
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      const formatted = formatDefaultWCs(
        response.data ? response.data : { data: [] }
      );
      return formatted;
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getChartReloadIntervalAsync = async (params = {}, { dispatch }) => {
  if (!params.noLoading) {
    dispatch(loadingPage(true));
  }
  try {
    const response = await apiClient.post("/systemConfig/getListConfigKeyVal", {
      type: "CHART_RELOAD_INTERVAL_TIME_TYPE",
    });
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      return response.data && response.data[0] && response.data[0].key
        ? Number(response.data[0].key)
        : 600000;
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

export const getLandingPages = createAsyncThunk(
  TYPES.GET_LANDING_PAGES,
  getLandingPagesAsync
);

export const getDefaultWarningConfigs = createAsyncThunk(
  TYPES.GET_DEFAULT_WARN_CONFIGS,
  getDefaultWarningConfigsAsync
);

export const getOperatorWarningConfigs = createAsyncThunk(
  TYPES.GET_OPERATOR_WARN_CONFIGS,
  getOperatorWarningConfigsAsync
);

export const getOperationSupportGuidanceList = createAsyncThunk(
    TYPES.GET_OPERATION_SUPPORT_GUIDANCE_TYPE,
    getOperationSupportGuidanceListAsync
);

export const getChartReloadInterval = createAsyncThunk(
  TYPES.GET_CHART_RELOAD_INTERVAL_TIME_TYPE,
  getChartReloadIntervalAsync
);
