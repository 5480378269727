// import external libs
import React, { useEffect, useState } from "react";
import { get, flatten, values } from "lodash";
import { Layout, Menu, Row, Col, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

// import internal libs
import "./sideBar.scss";
import Logo from "@src/styles/icon/logo.svg";
import LogoNoText from "@src/styles/icon/logo.png";
import { selectTab } from "@src/redux/sideBar";
import IconOfficeIcon from "@src/styles/icon/icon_office.svg";
import OfficeManagementIcon from "@src/styles/icon/office_management.svg";
import UserManagementIcon from "@src/styles/icon/user_management.svg";
import UserManagementActive from "@src/styles/icon/user_management_active.svg";

import MonitoringScreenIcon from "@src/styles/icon/office_1.svg";
import WarningListIcon from "@src/styles/icon/opening-times_1.svg";
import SettingIcon from "@src/styles/icon/decentralize_1.svg";
import AccessManagementIcon from "@src/styles/icon/digital-key_1.svg";
import SystemManagementIcon from "@src/styles/icon/settings-gear_1.svg";
import PlaceListIcon from "@src/styles/icon/bullet-list-69_1.svg";
import PlaceDetailIcon from "@src/styles/icon/View_detail.svg";
import UserSettingIcon from "@src/styles/icon/user-frame-33_1.svg";
import RoleSettingIcon from "@src/styles/icon/algorithm_1.svg";
import WarnSettingIcon from "@src/styles/icon/Alarm.svg";
import DashboardSettingIcon from "@src/styles/icon/Monitor.svg";
import NotifySettingIcon from "@src/styles/icon/Notifications.svg";
import SystemMonitoringIcon from "@src/styles/icon/System_supervisor.svg";
import StorageIcon from "@src/styles/icon/Storage.svg";
import SystemSettingIcon from "@src/styles/icon/System_Setup.svg";
import OfficeSettingIcon from "@src/styles/icon/Folder.svg";
import PlaceSettingIcon from "@src/styles/icon/Workplace.svg";
import SensorSettingIcon from "@src/styles/icon/Sensor.svg";
import CameraSettingIcon from "@src/styles/icon/Camera.svg";
import CloseSliderIcon from "@src/styles/icon/gg_chevron-double-left.svg";
import OpenSliderIcon from "@src/styles/icon/gg_chevron-double-right.svg";
import DownloadSideBarIcon from "@src/styles/icon/download_sidebar.svg";

import ROUTER_PAGE from "../../constants/router-constants";
import { checkRole, checkListRole } from "../../utils/checkRole";
import { ROLE_CONST } from "../../constants/role-constants";
import {
  getDashboardPageKeyStorage,
  getDashboardPageObjId,
} from "../../utils/dealCacheData";
import { selectorOfficeOwnUser } from "../../redux/auth";
import { selectorOfficeDetail } from "../../redux/modules/system/reducer";
import { collapsed as collapsedSideBar } from "@src/redux/sideBar";

const { SubMenu } = Menu;
const { Sider } = Layout;
const ROUTER_WITH_ALL_OFFICE = [
  ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE,
];

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

function SideBar() {
  const history = useHistory();
  let { collapsed, tabChild, tabParent } = useSelector(
    (state) => state.sideBar
  );
  const officeListHeader = useSelector(selectorOfficeOwnUser) || [];
  const officeIdUrl = history.location.pathname.match(/\-?\d+/g)
    ? Number(history.location.pathname.match(/\-?\d+/g)[0])
    : null;
  const officeSelected =
    officeIdUrl != -1 ? officeIdUrl : localStorage.getItem("officeSelected");
  const { role, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const officeSelected = localStorage.getItem("officeSelected");
  const dashboardSavedStorerage = getDashboardPageObjId(officeSelected);
  let pathname = history.location.pathname.replace(/\/?\-?\d+/g, "");
  if (pathname.includes("/null")) {
    pathname = pathname.slice(0, -5);
  }
  const officeIdFistOfArray =
    officeListHeader.length > 0
      ? ROUTER_WITH_ALL_OFFICE.indexOf(pathname) != -1 &&
        officeListHeader.length > 1
        ? officeListHeader[1].value
        : officeListHeader[0].value
      : officeSelected;
  const officeIdDefault = user.userFactoryId
    ? user.userFactoryId
    : officeIdFistOfArray;
  const dashboardId =
    officeIdUrl == -1
      ? localStorage.getItem(
          ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE
        )
        ? localStorage.getItem(
            ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE
          )
        : officeIdDefault
      : officeSelected;
  const dashboardOfficeId =
    officeIdUrl == -1
      ? localStorage.getItem(
          ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE
        )
        ? localStorage.getItem(
            ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE
          )
        : officeIdDefault
      : officeSelected;
  const warningHistoryId =
    officeIdUrl == -1
      ? localStorage.getItem(
          ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_PAGE
        ) || officeIdDefault
      : officeSelected;
  const warningEventId =
    officeIdUrl == -1
      ? localStorage.getItem(
          ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE
        ) || officeIdDefault
      : officeSelected;
  const warningSettingId =
    officeIdUrl == -1
      ? localStorage.getItem(
          ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE
        ) || officeIdDefault
      : officeSelected;
  const dashboardSettingId =
    officeIdUrl == -1
      ? localStorage.getItem(
          ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE
        ) || officeIdDefault
      : officeSelected;
  const officeSettingId =
    officeIdUrl == -1
      ? localStorage.getItem(
          ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE
        ) || officeIdDefault
      : officeSelected;
  const officeDasboardSelected =
    dashboardSavedStorerage && dashboardSavedStorerage.officeId
      ? dashboardSavedStorerage.officeId
      : dashboardId;
  const toggle = (collapse) => {
    // console.log(collapse);
  };

  const size = useWindowSize();
  const isMobile = size.width < 1000;

  const clickMenu = (item) => {
    item.keyPath.shift(0);
    let arrayChild = item.key;
    let arrayParent = item.keyPath[0];
    dispatch(selectTab({ tabParent: arrayParent, tabChild: arrayChild }));
  };

  const currentLocation = get(history, "location.pathname", "");

  const currentRouter = () => {
    const routers = {
      ...ROUTER_PAGE.ROUTER_PAGE,
      ...ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT,
      ...ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT,
      ...ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT,
    };

    const currentPage = currentLocation.replace(/\/?\-?\d+/g, "");
    let current = "";

    for (const [key, value] of Object.entries(routers)) {
      if (value === currentPage) current = key;
    }
    return current;
  };

  const currentPage = currentRouter();

  const renderMenuBottomDivider = () => {
    return collapsed ? null : <hr className="menu-bottom-divider" />;
  };

  const renderSubMenuBottomDivider = () => {
    return <hr className="sub-menu-bottom-divider" />;
  };

  const toggleButton = () => {
    dispatch(collapsedSideBar(!collapsed));
    // updateExpandSB(!collapsed);
  };

  const SETTING_SUBMENU_KEYS = [
    "WARNING_SETTING_PAGE",
    "DASHBOARD_SETTING_PAGE",
    "OFFICE_SETTINGS_PAGE",
  ];
  const ACCESS_SUBMENU_KEYS = ["USER_MANAGEMENT_PAGE", "ROLE_MANAGEMENT_PAGE"];
  const SYSTEM_SUBMENU_KEYS = [
    "SYSTEM_WARNING_HISTORY_PAGE",
    "FILE_STORAGE_PAGE",
    "SYSTEM_SETTING_PAGE",
    "OFFICE_SETTING_PAGE",
    "PROCESS_PLACE_SETTING_PAGE",
    "SENSOR_SETTING_PAGE",
    "CAMERA_SETTING_PAGE",
  ];
  const isSubMenuSelected = (tabKeys, currentPage) => {
    return tabKeys.includes(currentPage);
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      width={256}
      onCollapse={(collapse) => toggle(collapse)}
      className="navigation-background"
      collapsedWidth={64}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div className="slider-icon">
        <Row
          className="rowNotMargin"
          align="middle"
          justify={collapsed ? "center" : "end"}
        >
          <Col>
            <Button className="buttonIcon" type="link" onClick={toggleButton}>
              {collapsed ? (
                <img
                  style={{ height: "22px" }}
                  src={OpenSliderIcon}
                  alt="logo"
                />
              ) : (
                <img
                  style={{ height: "22px" }}
                  src={CloseSliderIcon}
                  alt="logo"
                />
              )}
            </Button>
          </Col>
        </Row>
      </div>
      <div className="logo" style={collapsed ? null : { marginTop: -20 }}>
        <Row className="rowNotMargin" align="middle" justify="center">
          <Col>
            {collapsed ? (
              <img style={{ height: "22px" }} src={LogoNoText} alt="logo" />
            ) : (
              <>
                <img
                  style={{ height: "66px", textAlign: "center" }}
                  src={Logo}
                  alt="logo"
                />
                <Row
                  justify="center"
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "bold",
                    paddingTop: "8px",
                  }}
                >
                  AI流入水量予測システム
                </Row>
              </>
            )}
          </Col>
        </Row>
      </div>
      <Menu
        theme="dark"
        mode="vertical"
        selectedKeys={[currentPage]}
        style={
          collapsed ? { borderRight: 0 } : { borderRight: 0, marginTop: 80 }
        }
        // selectedKeys={["warning", "WARNING_SETTING_PAGE"]}
        // openKeys={["warning"]}

        onClick={clickMenu}
      >
        {/*事業所*/}
        {checkRole(role, ROLE_CONST.P600) && (
          <>
            <Menu.Item
              className="customMenuItem"
              key="DASHBOARD_OFFICE_PAGE"
              icon={
                <span role="img" className="anticon">
                  <img className="iconSideBar" src={PlaceListIcon} alt="logo" />
                </span>
              }
            >
              <Link
                to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE}/${dashboardOfficeId}`}
              >
                施設一覧
              </Link>
            </Menu.Item>
            {renderMenuBottomDivider()}
          </>
        )}

        {/*施設詳細*/}
        {checkListRole(role, [ROLE_CONST.P600]) && (
          <>
            <Menu.Item
              className="customMenuItem"
              key="DASHBOARD_PAGE"
              icon={
                <span role="img" className="anticon">
                  <img
                    className="iconSideBar"
                    src={PlaceDetailIcon}
                    alt="logo"
                  />
                </span>
              }
            >
              <Link
                to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE}/${officeDasboardSelected}`}
              >
                施設詳細
              </Link>
            </Menu.Item>
            {renderMenuBottomDivider()}
          </>
        )}

        {/*警報一覧*/}
        {checkRole(role, ROLE_CONST.P500) && (
          <>
            <Menu.Item
              className="customMenuItem"
              key="WARNING_EVENT_PAGE"
              icon={
                <span role="img" className="anticon">
                  <img
                    className="iconSideBar"
                    src={WarningListIcon}
                    alt="logo"
                  />
                </span>
              }
            >
              <Link
                to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE}/${warningEventId}`}
              >
                警報一覧
              </Link>
            </Menu.Item>
            {renderMenuBottomDivider()}
          </>
        )}

        {/*設定*/}
        {checkListRole(role, [
          ROLE_CONST.P200,
          ROLE_CONST.P201,
          ROLE_CONST.P600,
          ROLE_CONST.P112,
        ]) && (
          <>
            <SubMenu
              className={`customSubMenu ${collapsed ? "collapsed" : ""} ${
                isSubMenuSelected(SETTING_SUBMENU_KEYS, currentPage)
                  ? "ant-menu-submenu-selected"
                  : ""
              }`}
              icon={
                <span role="img" className="anticon">
                  <img className="iconSideBar" src={SettingIcon} alt="logo" />
                </span>
              }
              key="warning"
              title="設定"
            >
              {/*警報設定*/}
              {checkListRole(role, [ROLE_CONST.P200, ROLE_CONST.P201]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="WARNING_SETTING_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={WarnSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE}/${warningSettingId}`}
                    >
                      警報設定
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*/!*精度誤差検知*!/*/}
              {/*{checkListRole(role, [ROLE_CONST.P203, ROLE_CONST.P204]) && (*/}
              {/*  <Menu.Item className="customMenuItem" key="DETECT_ERROR_PAGE">*/}
              {/*    <Link*/}
              {/*      to={*/}
              {/*        ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT*/}
              {/*          .DETECT_ERROR_PAGE*/}
              {/*      }*/}
              {/*    >*/}
              {/*      精度誤差検知*/}
              {/*    </Link>*/}
              {/*  </Menu.Item>*/}
              {/*)}*/}

              {/*画面構成設定*/}
              {checkRole(role, ROLE_CONST.P600) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="DASHBOARD_SETTING_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={DashboardSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE}/${dashboardSettingId}`}
                    >
                      画面構成設定
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*通知・表示設定*/}
              {checkRole(role, [ROLE_CONST.P112]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="OFFICE_SETTINGS_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={NotifySettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE}/${officeSettingId}`}
                    >
                      通知・表示設定
                    </Link>
                  </Menu.Item>
                  {/* {renderSubMenuBottomDivider()} */}
                </>
              )}
            </SubMenu>
            {renderMenuBottomDivider()}
          </>
        )}

        {/*アクセス管理*/}
        {checkListRole(role, [
          ROLE_CONST.P108,
          ROLE_CONST.P109,
          ROLE_CONST.P110,
          ROLE_CONST.P111,
        ]) && (
          <>
            <SubMenu
              className={`customSubMenu ${collapsed ? "collapsed" : ""} ${
                isSubMenuSelected(ACCESS_SUBMENU_KEYS, currentPage)
                  ? "ant-menu-submenu-selected"
                  : ""
              }`}
              key="userManage"
              icon={
                <span role="img" className="anticon">
                  <img
                    className="iconSideBar"
                    src={AccessManagementIcon}
                    alt="logo"
                  />
                </span>
              }
              title="アクセス管理"
            >
              {/*ユーザー設定*/}
              {checkListRole(role, [ROLE_CONST.P108, ROLE_CONST.P109]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="USER_MANAGEMENT_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={UserSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE}/${officeSelected}`}
                    >
                      ユーザー設定
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*ロール*/}
              {checkListRole(role, [ROLE_CONST.P110, ROLE_CONST.P111]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="ROLE_MANAGEMENT_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={RoleSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE}/${officeSelected}`}
                    >
                      役割設定
                    </Link>
                  </Menu.Item>
                  {/* {renderSubMenuBottomDivider()} */}
                </>
              )}
            </SubMenu>
            {renderMenuBottomDivider()}
          </>
        )}

        {/*システム管理*/}
        {checkListRole(role, [
          ROLE_CONST.P101,
          ROLE_CONST.P102,
          ROLE_CONST.P103,
          ROLE_CONST.P106,
          ROLE_CONST.P107,
          ROLE_CONST.P104,
          ROLE_CONST.P105,
          ROLE_CONST.P300,
          ROLE_CONST.P302,
        ]) && (
          <>
            <SubMenu
              className={`customSubMenu ${collapsed ? "collapsed" : ""} ${
                isSubMenuSelected(SYSTEM_SUBMENU_KEYS, currentPage)
                  ? "ant-menu-submenu-selected"
                  : ""
              }`}
              key="setting"
              icon={
                <span role="img" className="anticon">
                  <img
                    className="iconSideBar"
                    src={SystemManagementIcon}
                    alt="logo"
                  />
                </span>
              }
              title="システム管理"
            >
              {/*システム監視*/}
              {checkRole(role, ROLE_CONST.P300) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="SYSTEM_WARNING_HISTORY_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={SystemMonitoringIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE}/${warningHistoryId}`}
                    >
                      システム監視
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*ストレージデータ監視*/}
              {checkListRole(role, [ROLE_CONST.P302]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="FILE_STORAGE_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={StorageIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE}/${officeSelected}`}
                    >
                      ストレージデータ監視
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*システム設定*/}
              {checkRole(role, ROLE_CONST.P101) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="SYSTEM_SETTING_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={SystemSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE}/${officeSelected}`}
                    >
                      システム設定
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*事業所設定*/}
              {checkListRole(role, [ROLE_CONST.P102, ROLE_CONST.P103]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="OFFICE_SETTING_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={OfficeSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE}/${officeSelected}`}
                    >
                      事業所設定
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*施設設定*/}
              {checkListRole(role, [ROLE_CONST.P104, ROLE_CONST.P105]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="PROCESS_PLACE_SETTING_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={PlaceSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE}/${officeSelected}`}
                    >
                      施設設定
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*センサー設定*/}
              {checkListRole(role, [ROLE_CONST.P104, ROLE_CONST.P105]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="SENSOR_SETTING_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={SensorSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE}/${officeSelected}`}
                    >
                      センサー設定
                    </Link>
                  </Menu.Item>
                  {renderSubMenuBottomDivider()}
                </>
              )}

              {/*カメラ設定*/}
              {checkListRole(role, [ROLE_CONST.P106, ROLE_CONST.P107]) && (
                <>
                  <Menu.Item
                    className="customMenuItem"
                    key="CAMERA_SETTING_PAGE"
                    icon={
                      <span role="img" className="anticon">
                        <img
                          className="iconSideBar"
                          src={CameraSettingIcon}
                          alt="logo"
                        />
                      </span>
                    }
                  >
                    <Link
                      to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE}/${officeSelected}`}
                    >
                      カメラ設定
                    </Link>
                  </Menu.Item>
                  {/* {renderSubMenuBottomDivider()} */}
                </>
              )}
            </SubMenu>
            {renderMenuBottomDivider()}
          </>
        )}
      </Menu>

      <Menu
        theme="dark"
        mode="vertical"
        style={{ borderRight: 0 }}
        className={isMobile ? "downloadSidebarMobile" : "downloadSidebar"}
        // selectedKeys={["warning", "WARNING_SETTING_PAGE"]}
        // openKeys={["warning"]}
      >
        <Menu.Item
          className="customMenuItem downloadSidebarLink"
          //key="CAMERA_SETTING_PAGE"
          icon={
            <span role="img" className="anticon">
              <img
                className="iconSideBar iconDownloadSideBar"
                src={DownloadSideBarIcon}
                alt="logo"
              />
            </span>
          }
        >
          <a href={process.env.REACT_APP_MANUAL_FILE_URL}>マニュアル</a>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default SideBar;
