import {cloneDeep} from "lodash";

const {userCode = ""} = JSON.parse(localStorage.getItem("userInfo") || "{}");
export const clearCacheStorage = () => {
    for( let k in window.localStorage) {
        if(!k.includes("dashboardPageKey")) {
            delete window.localStorage[k];
        }
    }
}
export const getDashboardPageKeyStorage = () => {
    let dashboardPageStorage = [];
    if (userCode && localStorage.getItem(`dashboardPageKey-${userCode}`)) {
        dashboardPageStorage =  JSON.parse(localStorage.getItem(`dashboardPageKey-${userCode}`));
    }
    return dashboardPageStorage;
}

export const setDashboardPageKeyStorage = (item) => {
    for( let k in window.localStorage) {
        if((!k.includes(userCode) || getDashboardPageKeyStorage().length == 0) && userCode) {

            const dashboardPageStorage = getDashboardPageKeyStorage();
            let dashboardPageListItem = cloneDeep(dashboardPageStorage);
            let checkValid = false;
            for (let dashboardItem of dashboardPageListItem) {
                if (item.officeId == dashboardItem.officeId) {
                    dashboardItem.processPlaceId = item.processPlaceId;
                    checkValid = true;
                } 
            }
            if (!checkValid) {
                dashboardPageListItem.push(item);
            }
            localStorage.setItem(`dashboardPageKey-${userCode}`, JSON.stringify(dashboardPageListItem));
        }
    }
}

export const removeDashboardPageObjId = (officeId) => {
    const dashboardPageStorage = getDashboardPageKeyStorage();
    let dashboardPageListItem = dashboardPageStorage.filter(item => item.officeId != officeId)
    localStorage.setItem(`dashboardPageKey-${userCode}`, JSON.stringify(dashboardPageListItem));
}

export const getDashboardPageObjId = (officeId) => {
    const dashboardPageStorage = getDashboardPageKeyStorage() || [];
    for (let dashboardItem of dashboardPageStorage) {
        if (officeId == dashboardItem.officeId) {
            return dashboardItem;
        }
    }
    return null;
}

export const removeDashboardPageKeyStorage = () => {
    if (userCode && localStorage.getItem(`dashboardPageKey-${userCode}`)) {
        localStorage.removeItem(`dashboardPageKey-${userCode}`);
    }
}

