import React from "react";

export const ROLE_CONST = {
  GP01: "GP01", // システム設定
  GP02: "GP02", // 運転管理設定
  GP03: "GP03", // システム監視
  GP04: "GP04", // 警報承認操作
  GP05: "GP05", // 基本権限
  P100: "P100", // 全事業所
  P101: "P101", // 実行設定
  P102: "P102", // 事業所閲覧
  P103: "P103", // 事業所管理（追加・編集・削除）
  P104: "P104", // 施設閲覧
  P105: "P105", // 施設管理（追加・編集・削除）
  P106: "P106", // カメラ閲覧
  P107: "P107", // カメラ管理（追加・編集・削除）
  P108: "P108", // ユーザー閲覧
  P109: "P109", // ユーザー管理（追加・編集・同期）
  P110: "P110", // 役割閲覧
  P111: "P111", // 役割管理（追加・編集・削除）
  P112: "P112", // 通知・表示設定画面
  P200: "P200", // 警報閾値閲覧
  P201: "P201", // 警報閾値管理（追加・編集・削除）
  P300: "P300", // システム監視
  P301: "P301", // 運転管理（閾値）上の警報通 知
  P302: "P302", // ストレージデータ操作
  P400: "P400", // 警報対応
  P401: "P401", // 警報確認
  P500: "P500", // 警報履歴監視
  P600: "P600", // 流入水量予測値監視
};
