import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tabParent: ["detailOffice", "dashboard"],
  tabChild: ["DASHBOARD_PAGE"],
  collapsed: true,
  visibleNotify: false,
};

const sideBarSlice = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    selectTab: (state, { payload }) => {
      state.tabParent = payload.tabParent;
      state.tabChild = payload.tabChild;
    },
    collapsed: (state, { payload }) => {
      state.collapsed = payload;
    },
    setVisibleNotify: (state, { payload }) => {
      state.visibleNotify = payload;
    },
  },
});

// the action
export const { selectTab, collapsed, setVisibleNotify } = sideBarSlice.actions;

//the selector
export const sideBarSelector = (state) => state.sideBar;

// the reducer
export default sideBarSlice.reducer;
