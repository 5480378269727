import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getLogs } from "./action";

const initialState = {
  logList: {
    data: [],
    error: {},
    isLoading: false,
  },
};

const logSlice = createSlice({
  name: "logs",
  initialState,
  extraReducers: {
    [getLogs.pending]: (state, action) => {
      state.logList.isLoading = true;
    },
    [getLogs.fulfilled]: (state, action) => {
      state.logList.isLoading = false;
      state.logList.data = get(action, "payload.data", []);
    },
    [getLogs.rejected]: (state, action) => {
      console.error("error", action);
      state.logList.isLoading = false;
      state.logList.data = [];
      state.logList.error = action.payload;
    },
  },
  reducers: {},
});

//the selector
export const logSelector = (state) => state.logs;

// the reducer
export default logSlice.reducer;
