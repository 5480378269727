import { createSlice } from "@reduxjs/toolkit";
import {
  getScreenCfg,
  applyScreenCfg,
  getOffice,
  createOrUpdateOffice,
  getSensor,
  createOrUpdateSensor,
  getProcessPlace,
  getWarnEvent,
  getWarnHistory,
  getWarnHistoryDetail,
  getFactoryConfig,
  getSystemConfig,
  getWarnTypeConfig,
  getWarnLevelConfig,
  getListPredictionSensor,
  getListActionLog,
  getListActionLogDetail,
  getSignedUrl,
  getFileStorage,
  deleteFileStorage,
  // downloadFileStorage,
  getStorageFileTypeConfig,
} from "./action";
const initialState = {
  screenCfg: {
    data: {},
    error: {},
    isLoading: false,
  },
  office: {
    officeDataList: [],
    error: {},
    isLoading: false,
    officeIdSelected: 0,
  },
  sensor: {
    sensorDataList: [],
    error: {},
    isLoading: false,
  },
  processPlace: {
    processDataList: [],
    error: {},
    isLoading: false,
  },
  warnEvent: {
    eventDataList: [],
    error: {},
    isLoading: false,
    totalRecords: 0
  },
  warnHistory: {
    historyDataList: [],
    error: {},
    isLoading: false,
  },
  warnHistoryDetail: {
    historyDetailData: {
      warnEvent: {},
      listWarnHis: [],
    },
    error: {},
    isLoading: false,
  },
  factoryConfig: {
    factoryConfigData: {
      listWarnLevel: [],
      listEmail: [],
    },
    error: {},
    isLoading: false,
  },
  systemConfig: {
    systemConfigData: {
      listEmail: [],
      listPredictionSensor: [],
      warnLevelList: [],
      operationSupportGuidance: [],
    },
    error: {},
    isLoading: false,
  },
  predictionSensor: {
    predictionSensorList: [],
    error: {},
    isLoading: false,
  },
  actionLog: {
    actionLogList: [],
    error: {},
    isLoading: false,
    totalRecords: 0
  },
  actionLogDetail: {
    actionLogDetailList: [],
    error: {},
    isLoading: false,
  },
  warnTypeConfig: {
    warnTypeConfigData: [],
    error: {},
    isLoading: false,
  },
  warnLevelConfig: {
    warnLevelConfigData: [],
    error: {},
    isLoading: false,
  },
  signedUrl: {
    signedUrlData: "",
    error: {},
    isLoading: false,
  },
  fileStorage: {
    fileStorageData: { 
      fileStorageHistoryDTOList: [],
      totalRecords: 0
    },
    error: {},
    isLoading: false,
  },
  storageFileTypeConfig: {
    storageFileTypeConfigData: [],
    error: {},
    isLoading: false,
  },
};

const systemSlice = createSlice({
  name: "system",
  initialState,
  extraReducers: {
    // reducer creator for screen config
    [getScreenCfg.pending]: (state, action) => {
      state.screenCfg.isLoading = true;
    },
    [getScreenCfg.fulfilled]: (state, action) => {
      state.screenCfg.isLoading = false;
      state.screenCfg.data = action.payload;
    },
    [getScreenCfg.rejected]: (state, action) => {
      state.screenCfg.data = {};
      state.screenCfg.isLoading = false;
      state.screenCfg.error = action.payload;
    },
    [applyScreenCfg.pending]: (state, action) => {
      state.screenCfg.isLoading = true;
    },
    [applyScreenCfg.fulfilled]: (state, action) => {
      state.screenCfg.isLoading = false;
    },
    [applyScreenCfg.rejected]: (state, action) => {
      state.screenCfg.isLoading = false;
      state.screenCfg.error = action.error;
    },
    // reducer creator for office
    [getOffice.pending]: (state, action) => {
      state.office.isLoading = true;
    },
    [getOffice.fulfilled]: (state, action) => {
      state.office.isLoading = false;
      state.office.officeDataList = action.payload;
    },
    [getOffice.rejected]: (state, action) => {
      state.office.officeDataList = [];
      state.office.isLoading = false;
      state.office.error = action.payload;
    },
    [createOrUpdateOffice.pending]: (state, action) => {
      state.office.isLoading = true;
    },
    [createOrUpdateOffice.fulfilled]: (state, action) => {
      state.office.isLoading = false;
    },
    [createOrUpdateOffice.rejected]: (state, action) => {
      state.office.isLoading = false;
      state.office.error = action.error;
    },
    // reducer creator for sensor
    [getSensor.pending]: (state, action) => {
      state.sensor.isLoading = true;
    },
    [getSensor.fulfilled]: (state, action) => {
      state.sensor.isLoading = false;
      state.sensor.sensorDataList = action.payload;
    },
    [getSensor.rejected]: (state, action) => {
      state.sensor.sensorDataList = [];
      state.sensor.isLoading = false;
      state.sensor.error = action.payload;
    },
    [createOrUpdateSensor.pending]: (state, action) => {
      state.sensor.isLoading = true;
    },
    [createOrUpdateSensor.fulfilled]: (state, action) => {
      state.sensor.isLoading = false;
    },
    [createOrUpdateSensor.rejected]: (state, action) => {
      state.sensor.isLoading = false;
      state.sensor.error = action.error;
    },
    // reducer creator for process place
    [getProcessPlace.pending]: (state, action) => {
      state.processPlace.isLoading = true;
    },
    [getProcessPlace.fulfilled]: (state, action) => {
      state.processPlace.isLoading = false;
      state.processPlace.processDataList = action.payload;
    },
    [getProcessPlace.rejected]: (state, action) => {
      state.processPlace.processDataList = [];
      state.processPlace.isLoading = false;
      state.processPlace.error = action.payload;
    },
    // reducer creator for warning event
    [getWarnEvent.pending]: (state, action) => {
      state.warnEvent.isLoading = true;
    },
    [getWarnEvent.fulfilled]: (state, action) => {
      state.warnEvent.isLoading = false;
      state.warnEvent.eventDataList = action.payload.eventDataList;
      state.warnEvent.totalRecords = action.payload.totalRecords;
    },
    [getWarnEvent.rejected]: (state, action) => {
      state.warnEvent.eventDataList = [];
      state.warnEvent.totalRecords = 0;
      state.warnEvent.isLoading = false;
      state.warnEvent.error = action.payload;
    },
    // reducer creator for warning history
    [getWarnHistory.pending]: (state, action) => {
      state.warnHistory.isLoading = true;
    },
    [getWarnHistory.fulfilled]: (state, action) => {
      state.warnHistory.isLoading = false;
      state.warnHistory.historyDataList = action.payload;
    },
    [getWarnHistory.rejected]: (state, action) => {
      state.warnHistory.historyDataList = [];
      state.warnHistory.isLoading = false;
      state.warnHistory.error = action.payload;
    },
    // reducer creator for warning history detail
    [getWarnHistoryDetail.pending]: (state, action) => {
      state.warnHistoryDetail.isLoading = true;
    },
    [getWarnHistoryDetail.fulfilled]: (state, action) => {
      state.warnHistoryDetail.isLoading = false;
      state.warnHistoryDetail.historyDetailData = action.payload;
    },
    [getWarnHistoryDetail.rejected]: (state, action) => {
      state.warnHistoryDetail.historyDetailData = {
        warnEvent: {},
        listWarnHis: [],
      };
      state.warnHistoryDetail.isLoading = false;
      state.warnHistoryDetail.error = action.payload;
    },
    // reducer creator for get factory config
    [getFactoryConfig.pending]: (state, action) => {
      state.factoryConfig.isLoading = true;
    },
    [getFactoryConfig.fulfilled]: (state, action) => {
      state.factoryConfig.isLoading = false;
      state.factoryConfig.factoryConfigData = action.payload;
    },
    [getFactoryConfig.rejected]: (state, action) => {
      state.factoryConfig.factoryConfigData = {
        listWarnLevel: [],
        listEmail: [],
      };
      state.factoryConfig.isLoading = false;
      state.factoryConfig.error = action.payload;
    },
    // reducer creator for get system config
    [getSystemConfig.pending]: (state, action) => {
      state.systemConfig.isLoading = true;
    },
    [getSystemConfig.fulfilled]: (state, action) => {
      state.systemConfig.isLoading = false;
      state.systemConfig.systemConfigData = action.payload;
    },
    [getSystemConfig.rejected]: (state, action) => {
      state.systemConfig.systemConfigData = {
        listEmail: [],
        listPredictionSensor: [],
        warnLevelList: [],
        operationSupportGuidance: []
      };
      state.systemConfig.isLoading = false;
      state.systemConfig.error = action.payload;
    },
    [getWarnTypeConfig.pending]: (state, action) => {
      state.warnTypeConfig.isLoading = true;
    },
    [getWarnTypeConfig.fulfilled]: (state, action) => {
      state.warnTypeConfig.isLoading = false;
      state.warnTypeConfig.warnTypeConfigData = action.payload;
    },
    [getWarnTypeConfig.rejected]: (state, action) => {
      state.warnTypeConfig.warnTypeConfigData = [];
      state.warnTypeConfig.isLoading = false;
      state.warnTypeConfig.error = action.payload;
    },
    [getWarnLevelConfig.pending]: (state, action) => {
      state.warnLevelConfig.isLoading = true;
    },
    [getWarnLevelConfig.fulfilled]: (state, action) => {
      state.warnLevelConfig.isLoading = false;
      state.warnLevelConfig.warnLevelConfigData = action.payload;
    },
    [getWarnLevelConfig.rejected]: (state, action) => {
      state.warnLevelConfig.warnLevelConfigData = [];
      state.warnLevelConfig.isLoading = false;
      state.warnLevelConfig.error = action.payload;
    },
    // reducer creator for get list predict model
    [getListPredictionSensor.pending]: (state, action) => {
      state.predictionSensor.isLoading = true;
    },
    [getListPredictionSensor.fulfilled]: (state, action) => {
      state.predictionSensor.isLoading = false;
      state.predictionSensor.predictionSensorList = action.payload;
    },
    [getListPredictionSensor.rejected]: (state, action) => {
      state.predictionSensor.predictionSensorList = [];
      state.predictionSensor.isLoading = false;
      state.predictionSensor.error = action.payload;
    },
    // reducer creator for get list action log
    [getListActionLog.pending]: (state, action) => {
      state.actionLog.isLoading = true;
    },
    [getListActionLog.fulfilled]: (state, action) => {
      state.actionLog.isLoading = false;
      state.actionLog.actionLogList = action.payload.actionLogList;
      state.actionLog.totalRecords = action.payload.totalRecords;
    },
    [getListActionLog.rejected]: (state, action) => {
      state.actionLog.actionLogList = [];
      state.actionLog.totalRecords = 0;
      state.actionLog.isLoading = false;
      state.actionLog.error = action.payload;
    },
    [getListActionLogDetail.pending]: (state, action) => {
      state.actionLogDetail.isLoading = true;
    },
    [getListActionLogDetail.fulfilled]: (state, action) => {
      state.actionLogDetail.isLoading = false;
      state.actionLogDetail.actionLogDetailList = action.payload;
    },
    [getListActionLogDetail.rejected]: (state, action) => {
      state.actionLogDetail.actionLogDetailList = [];
      state.actionLogDetail.isLoading = false;
      state.actionLogDetail.error = action.payload;
    },
    // reducer creator for get signed url
    [getSignedUrl.pending]: (state, action) => {
      state.signedUrl.isLoading = true;
    },
    [getSignedUrl.fulfilled]: (state, action) => {
      state.signedUrl.isLoading = false;
      state.signedUrl.signedUrlData = action.payload;
    },
    [getSignedUrl.rejected]: (state, action) => {
      state.signedUrl.signedUrlData = "";
      state.signedUrl.isLoading = false;
      state.signedUrl.error = action.payload;
    },
    // reducer creator for file storage
    [getFileStorage.pending]: (state, action) => {
      state.fileStorage.isLoading = true;
    },
    [getFileStorage.fulfilled]: (state, action) => {
      state.fileStorage.isLoading = false;
      state.fileStorage.fileStorageData = action.payload;
    },
    [getFileStorage.rejected]: (state, action) => {
      state.fileStorage.fileStorageData = { fileStorageHistoryDTOList: [], totalRecords: 0 };
      state.fileStorage.isLoading = false;
      state.fileStorage.error = action.payload;
    },
    // [deleteFileStorage.pending]: (state, action) => {
    //   state.fileStorage.isLoading = true;
    // },
    // [deleteFileStorage.fulfilled]: (state, action) => {
    //   state.fileStorage.isLoading = false;
    //   // state.fileStorage.fileStorageDataList = action.payload;
    // },
    // [deleteFileStorage.rejected]: (state, action) => {
    //   state.fileStorage.fileStorageDataList = [];
    //   state.fileStorage.isLoading = false;
    //   state.fileStorage.error = action.payload;
    // },
    // [downloadFileStorage.pending]: (state, action) => {
    //   state.fileStorage.isLoading = true;
    // },
    // [downloadFileStorage.fulfilled]: (state, action) => {
    //   state.fileStorage.isLoading = false;
    //   // state.fileStorage.fileStorageDataList = action.payload;
    // },
    // [downloadFileStorage.rejected]: (state, action) => {
    //   state.fileStorage.fileStorageDataList = [];
    //   state.fileStorage.isLoading = false;
    //   state.fileStorage.error = action.payload;
    // },
    [getStorageFileTypeConfig.pending]: (state, action) => {
      state.storageFileTypeConfig.isLoading = true;
    },
    [getStorageFileTypeConfig.fulfilled]: (state, action) => {
      state.storageFileTypeConfig.isLoading = false;
      state.storageFileTypeConfig.storageFileTypeConfigData = action.payload;
    },
    [getStorageFileTypeConfig.rejected]: (state, action) => {
      state.storageFileTypeConfig.storageFileTypeConfigData = [];
      state.storageFileTypeConfig.isLoading = false;
      state.storageFileTypeConfig.error = action.payload;
    },
  },
  reducers: {
    setOfficeIdSelected: (state, { payload }) => {
      state.office.officeIdSelected = payload;
    },
    setSignedUrl: (state, { payload }) => {
      state.signedUrl.signedUrlData = payload;
    },
  },
});

//the action
export const { setOfficeIdSelected } = systemSlice.actions;
export const { setSignedUrl } = systemSlice.actions;

//the selector
export const selectorScreenCfg = (state) => state.system.screenCfg;
export const selectorOffice = (state) => state.system.office.officeDataList;
export const selectorOfficeDetail = (state) =>
  state.system.office.officeIdSelected;

export const selectorSensor = (state) => state.system.sensor.sensorDataList;

export const selectorProcessPlace = (state) =>
  state.system.processPlace.processDataList;

export const selectorWarnEvent = (state) =>
  state.system.warnEvent;

export const selectorWarnHistory = (state) =>
  state.system.warnHistory.historyDataList;

export const selectorWarnHistoryDetail = (state) =>
  state.system.warnHistoryDetail.historyDetailData;

export const selectorFactoryConfig = (state) =>
  state.system.factoryConfig.factoryConfigData;

export const selectorSystemConfig = (state) =>
  state.system.systemConfig.systemConfigData;

export const selectorWarnTypeConfig = (state) =>
  state.system.warnTypeConfig.warnTypeConfigData;

export const selectorWarnLevelConfig = (state) =>
  state.system.warnLevelConfig.warnLevelConfigData;

export const selectorPredictionSensor = (state) =>
  state.system.predictionSensor.predictionSensorList;

export const selectorActionLog = (state) =>
  state.system.actionLog;

export const selectorActionLogDetail = (state) =>
  state.system.actionLogDetail.actionLogDetailList;

export const selectorSignedUrl = (state) =>
  state.system.signedUrl.signedUrlData;

export const selectorFileStorage = (state) =>
  state.system.fileStorage.fileStorageData;

export const selectorStorageFileTypeConfig = (state) =>
  state.system.storageFileTypeConfig.storageFileTypeConfigData;

// the reducer
export default systemSlice.reducer;
