import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  getLandingPages,
  getDefaultWarningConfigs,
  getOperatorWarningConfigs,
  getChartReloadInterval,
  getOperationSupportGuidanceList,
} from "./action";

const initialState = {
  landingPageList: {
    data: [],
    error: {},
    isLoading: false,
  },
  defaultWCs: {
    data: {},
    error: {},
    isLoading: false,
  },
  operatorWCs: {
    data: [],
    error: {},
    isLoading: false,
  },
  operationSupportGuidanceList: {
    data: [],
    error: {},
    isLoading: false,
  },
  manualOperationTypeList: {
    data: [],
    error: {},
    isLoading: false,
  },
  chartReloadInterval: 600000,
};

const configSlice = createSlice({
  name: "configs",
  initialState,
  extraReducers: {
    // get chart reload interval
    [getChartReloadInterval.fulfilled]: (state, action) => {
      state.chartReloadInterval = action.payload;
    },
    // get list landing pages
    [getLandingPages.pending]: (state, action) => {
      state.landingPageList.isLoading = true;
    },
    [getLandingPages.fulfilled]: (state, action) => {
      state.landingPageList.isLoading = false;
      state.landingPageList.data = get(action, "payload.data", []);
    },
    [getLandingPages.rejected]: (state, action) => {
      state.landingPageList.isLoading = false;
      state.landingPageList.data = [];
      state.landingPageList.error = action.payload;
    },

    // get list default warning configs
    [getDefaultWarningConfigs.pending]: (state, action) => {
      state.defaultWCs.isLoading = true;
    },
    [getDefaultWarningConfigs.fulfilled]: (state, action) => {
      state.defaultWCs.isLoading = false;
      state.defaultWCs.data = get(action, "payload", {});
    },
    [getDefaultWarningConfigs.rejected]: (state, action) => {
      state.defaultWCs.isLoading = false;
      state.defaultWCs.data = {};
      state.defaultWCs.error = action.payload;
    },

    // get list operator warning configs
    [getOperatorWarningConfigs.pending]: (state, action) => {
      state.operatorWCs.isLoading = true;
    },
    [getOperatorWarningConfigs.fulfilled]: (state, action) => {
      state.operatorWCs.isLoading = false;
      state.operatorWCs.data = get(action, "payload", []);
    },
    [getOperatorWarningConfigs.rejected]: (state, action) => {
      state.operatorWCs.isLoading = false;
      state.operatorWCs.data = [];
      state.operatorWCs.error = action.payload;
    },

    // get list operation support guidance
    [getOperationSupportGuidanceList.pending]: (state, action) => {
      state.operationSupportGuidanceList.isLoading = true;
    },
    [getOperationSupportGuidanceList.fulfilled]: (state, action) => {
      state.operationSupportGuidanceList.isLoading = false;
      state.operationSupportGuidanceList.data = get(action, "payload", []);
    },
    [getOperationSupportGuidanceList.rejected]: (state, action) => {
      state.operationSupportGuidanceList.isLoading = false;
      state.operationSupportGuidanceList.data = [];
      state.operationSupportGuidanceList.error = action.payload;
    },
  },
  reducers: {},
});

//the selector
export const configSelector = (state) => state.configs;

// the reducer
export default configSlice.reducer;
