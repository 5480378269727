import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// More document https://react.i18next.com/getting-started

const EN = require("./languages/en.json");
const JP = require("./languages/jp.json");

const resources = {
  en: {
    translation: EN,
  },
  jp: {
    translation: JP,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "jp",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
