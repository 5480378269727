import apiClient from "./api";
import { loadingPage } from "../loading";

const systemApi = {
  // define call api for screen config
  getScreenCfg: async (data) => {
    try {
      const url = "/screenConfig/getListConfig";
      const response = await apiClient.post(url, data);
      if (response.data && Number(response.data.code) === 0) {
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      throw error;
    }
  },
  applyScreenCfg: async (data) => {
    try {
      const url = "/screenConfig/applyScreenCfg";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      throw error;
    }
  },
  // define call api for office
  getOffice: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/factory/search";
      const response = await apiClient.post(url, data);
      if ((response.data && Number(response.data.code) === 0) || !isSearch) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  createOrUpdateOffice: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/factory/createOrUpdate";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for sensor
  getSensor: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/device/search";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch)
        throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  createOrUpdateSensor: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/device/createOrUpdate";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },

  // define call api for process place
  getProcessPlace: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/processPlace/search";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        if (params.callback) {
          params.callback(response.data.data);
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  createOrUpdateProcess: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/processPlace/createOrUpdate";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for warn event
  getWarnEvent: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/warn/searchWarnEvent";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  updateWarnStatus: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/warn/updateWarnStatus";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for warn history
  getWarnHistory: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/warn/searchWarnHistory";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for warn history detail
  getWarnHistoryDetail: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/warn/getWarnEventDetail";
      const response = await apiClient.get(url, { params: data });
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for get factory config
  getFactoryConfig: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/systemConfig/getFactoryConfig";
      const response = await apiClient.get(url, { params: data });
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  createFactoryConfig: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/systemConfig/createFactoryConfig";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for get system config
  getSystemConfig: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/systemConfig/getSystemConfig";
      const response = await apiClient.get(url, { params: data });
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  createSystemConfig: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/systemConfig/createSystemConfig";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  getWarnTypeConfig: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/systemConfig/getListConfig";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  getWarnLevelConfig: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/systemConfig/getListConfig";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for list predict modal
  getListPredictionSensor: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/device/getListPredictionSensor";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for list action log
  getListActionLog: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/actionLog/searchSysLog";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  getListActionLogDetail: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/actionLog/search";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for azure signed url
  getSignedUrl: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/storage/getSignedUrl";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  // define call api for file storage
  getFileStorage: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/storage/search";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && (Number(response.data.code) === 0 || Number(response.data.code) === 404)) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  deleteFileStorage: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/storage/delete";
      const response = await apiClient.post(url, data);
      if (response && response.data && Number(response.data.code) === 0) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      throw new Error(response.data.message || "内部サーバーエラー");
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
  getStorageFileTypeConfig: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/systemConfig/getListConfig";
      const response = await apiClient.post(url, data);
      if (
        (response && response.data && Number(response.data.code) === 0) ||
        !isSearch
      ) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
};

export default systemApi;
