export const GET_SCREEN_CFG = "system/getScreenCfg";
export const APPLY_SCREEN_CFG = "system/applyScreenCfg";
export const GET_OFFICE = "system/getOffice";
export const CREATE_OR_UPDATE_OFFICE = "system/createOrUpdateOffice";
export const GET_SENSOR = "system/getSensor";
export const CREATE_OR_UPDATE_SENSOR = "system/createOrUpdateSensor";
export const GET_PROCESS_PLACE = "system/getProcessPlace";
export const CREATE_OR_UPDATE_PROCESS_PLACE =
  "system/createOrUpdateProcessPlace";
export const GET_WARN_EVENT = "system/getWarnEvent";
export const UPDATE_WARN_STATUS = "system/updateWarnStatus";
export const GET_WARN_HISTORY = "system/getWarnHistory";
export const GET_WARN_HISTORY_DETAIL = "system/getWarnHistoryDetail";
export const GET_FACTORY_CONFIG = "system/getFactoryConfig";
export const CREATE_FACTORY_CONFIG = "system/createFactoryConfig";
export const GET_SYSTEM_CONFIG = "system/getSystemConfig";
export const CREATE_SYSTEM_CONFIG = "system/createSystemConfig";
export const GET_WARN_TYPE_CONFIG = "system/getWarnTypeConfig";
export const GET_WARN_LEVEL_CONFIG = "system/getWarnLevelConfig";
export const GET_LIST_PREDICTION_SENSOR = "system/getListPredictionSensor";
export const GET_LIST_ACTION_LOG = "actionLog/getListActionLog";
export const GET_LIST_ACTION_LOG_DETAIL = "actionLog/searchSysLogDetail";
export const GET_SIGNED_URL = "storage/getSignedUrl";
export const GET_FILE_STORAGE = "system/getFileStorage";
export const DOWNLOAD_FILE_STORAGE = "system/downloadFileStorage";
export const DELETE_FILE_STORAGE = "system/deleteFileStorage";
export const GET_STORAGE_FILE_TYPE_CONFIG = "system/getStorageFileTypeConfig";
