import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import RouterAuth from "./routerAuth.jsx";
import NotFoundPage from "../pages/not-found-page/not-found-page";
import ErrorPage from "../pages/error-page/error-page";
import LoginPage from "../pages/login-page/login-page";
import LogOutPage from "../pages/login-page/logout-page";
import { authenticate, updateRole, setUserLogin } from "@src/redux/auth";
import { IdleTimeoutManager } from "idle-timer-manager";
import { setError } from "../redux/error";
import { clearCache, getToken } from "../redux/services/config";

function RouterRoot() {
  const dispatch = useDispatch();
  let history = useHistory();
  const token = getToken();
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  if (userInfo) {
    dispatch(authenticate(true));
    dispatch(setUserLogin(userInfo));
    dispatch(updateRole(JSON.stringify(userInfo.userPermission)));
  } else {
    localStorage.setItem("userInfo", "{}");
    localStorage.setItem("currentUrl", window.location.href);
    history.push("/login");
    window.location.reload();
  }

  useEffect(() => {
    const manager = new IdleTimeoutManager({
      timeout: userInfo ? (userInfo.timeout || 1800) : 1800, //expired after timeout of user
      onExpired: (time) => {
        // localStorage.setItem("info-error-page", JSON.stringify({
        //   actionLabel: t("commons.go_to_login"),
        //   message: `<span>申し訳ございません。</span></br>
        //   <span>所定の時間を超過しましたので接続を切らせていただきました。</span></br>
        //   <span>画面を閉じて再度お手続き願います。</span></br>`,
        //   logOut: true
        // }))
        dispatch(
          setError({
            message: `<span>申し訳ございません。</span></br>
              <span>所定の時間を超過しましたので接続を切らせていただきました。</span></br>
              <span>画面を閉じて再度お手続き願います。</span></br>`
          })
        );
        // sessionStorage.setItem("isErrorTimeout", true);
        history.push("/error");
      }
    });
    return () => {
      manager.clear();
    }
  }, []);

  // const loginCount = localStorage.getItem("loginCount");
  // const landingPage = localStorage.getItem("landingPage");
  // let urlmail = window.location.href.split("#");
  // let newUrl;
  // console.log("PROCESS.ENV.REACT_APP", process.env.REACT_APP_ENV);
  // if (
  //   landingPage &&
  //   ACCESS_ROUTER_FROM_EMAIL.includes(urlmail[0].split("=")[0])
  // ) {
  //   if (urlmail[0].includes("3000")) {
  //     newUrl = urlmail[0].replace(/3000\/{1}/, "3000/#/");
  //   } else {
  //     newUrl = urlmail[0].replace(/net\/{1}/, "net/#/");
  //   }
  //   localStorage.setItem("loginCount", Number(loginCount) + 1);
  //   window.open(newUrl, "_self");
  // } else {
  //   if (Number(loginCount) <= 1 && landingPage) {
  //     history.push(landingPage);
  //     localStorage.setItem("loginCount", Number(loginCount) + 1);
  //     window.location.reload();
  //   }
  // }
  if (!token) {
    clearCache();
    window.location.reload();
  }

  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/logout" component={LogOutPage} />
      <Route exact path="/404" component={NotFoundPage} />
      <Route exact path="/error" component={ErrorPage} />
      <RouterAuth />
    </Switch>
  );
}

export default RouterRoot;
