import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { notification } from "antd";
import apiClient from "../../services/api";
import { loadingPage } from "../../loading";

import * as TYPES from "./type";

const getUsersAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/user/search", params.data);
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      const data = get(response, "data.data", []).map((item, index) => {
        item.key = index;
        return item;
      });
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return { ...response.data, data };
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    return response.data;
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const createOrUpdateUserAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/user/createOrUpdate", params.data);
    const data = response.data;
    if (Number(data.code) === 0) {
      if (params && params.callback) {
        params.callback(data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    } else {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      notification.error({
        message: data.message ? data.message : "内部サーバーエラー",
      });
    }
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const syncUserAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/user/sync");
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : {};
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: response.data.message || "内部サーバーエラー",
    });
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getADUserAsync = async (params = {}, { dispatch }) => {
  if (params && params.isReset) {
    return { data: {} };
  }
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/user/getAdUserInfo", params.data);
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: {} };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: response.data.message || "内部サーバーエラー",
    });
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getRolesAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      // dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/role/search", params.data || {});
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      const data = get(response, "data.data", []).map((item, index) => {
        item.key = index;
        return item;
      });
      if (!params.noLoading) {
        // dispatch(loadingPage(false));
      }
      return { ...response.data, data };
    }
    if (!params.noLoading) {
      // dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return response.data;
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const createOrUpdateRoleAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/role/createOrUpdate", params.data);
    const data = response.data;
    if (Number(data.code) === 0) {
      if (params && params.callback) {
        params.callback(data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    } else {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getPermissonsAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/permission/getListTreeData");
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }

      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

export const getUsers = createAsyncThunk(TYPES.GET_USERS, getUsersAsync);
export const createOrUpdateUser = createAsyncThunk(
  TYPES.CREATE_OR_UPDATE_USER,
  createOrUpdateUserAsync
);
export const synceUsers = createAsyncThunk(TYPES.SYNC_USERS, syncUserAsync);
export const getADUser = createAsyncThunk(TYPES.GET_AD_USER, getADUserAsync);

export const getRoles = createAsyncThunk(TYPES.GET_ROLES, getRolesAsync);
export const createOrUpdateRole = createAsyncThunk(
  TYPES.CREATE_OR_UPDATE_ROLE,
  createOrUpdateRoleAsync
);

export const getPermissons = createAsyncThunk(
  TYPES.GET_PERMISSIONS,
  getPermissonsAsync
);
