import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "lodash";
import { notification } from "antd";
import apiClient from "../../services/api";
import { loadingPage } from "../../loading";

import * as TYPES from "./type";

const getCamerasAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/camera/search", params.data);
    let data = [];
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      data = get(response, "data.data", []).map((item, index) => {
        item.key = index;
        item["name_option"] = item.name;
        item["id_option"] = item.cameraId;
        return item;
      });
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    return data;
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      notification.error({
        message: "内部サーバーエラー",
      });
    }
  }
};

const createOrUpdateCameraAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/camera/createOrUpdate",
      params.data
    );
    const data = response.data;
    if (Number(data.code) === 0) {
      if (params && params.callback) {
        params.callback(data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    } else {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      notification.error({
        message: data.message ? data.message : "内部サーバーエラー",
      });
    }
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getCameraHistoriesAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/storage/search", params.data);
    let data = [];
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      data = get(response, "data.data.fileStorageHistoryDTOList", []).map(
        (item, index) => {
          item.key = index;
          return item;
        }
      );
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    return data;
  } catch (error) {
    console.log(error);
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

export const getCameras = createAsyncThunk(TYPES.GET_CAMERAS, getCamerasAsync);
export const createOrUpdateCamera = createAsyncThunk(
  TYPES.CREATE_OR_UPDATE_CAMERA,
  createOrUpdateCameraAsync
);

export const getCameraHistories = createAsyncThunk(
  TYPES.GET_CAMERA_HISTORIES,
  getCameraHistoriesAsync
);
