import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isLoading: false,
  count: 0,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    loadingPage: (state, { payload }) => {
      const reCount = payload ? state.count + 1 : state.count - 1;
      state.isLoading = reCount > 0;
      state.count = reCount;
    },
  },
});

// the action
export const { loadingPage, loadingInline } = loadingSlice.actions;

//the selector
export const loadingSelector = (state) => state.loading;

// the reducer
export default loadingSlice.reducer;
