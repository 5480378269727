import axios from "axios";
import { clearCache, getToken } from "./config";
import { clearCacheStorage } from "../../utils/dealCacheData";
require("dotenv").config();

const API_URL = process.env.REACT_APP_API_URL;
const TIMEOUT = process.env.REACT_APP_TIMEOUT;

const api = (http) => {
  const apiClient = axios.create({
    baseURL: API_URL,
    headers: http || {
      Accept: "*/*",
      "Content-Type": "application/json",
    },
    timeout: TIMEOUT,
  });
  apiClient.interceptors.request.use(
    (config) => {
      const token = `Bearer ${getToken()}`;
      config.headers.Authorization = token;

      return {
        ...config,
        // headers: apiClient.headers,
      };
    },
    (error) => {
      console.log("error", error);
      if (Number(error.status) === 401 || Number(error.status) === 403) {
        if (!sessionStorage.getItem("notReload") && sessionStorage.getItem("reloadCount") != 1) {
          clearCacheStorage();
          sessionStorage.setItem("reloadCount", 1);
          window.location.reload();
        } else {
          sessionStorage.setItem("reloadCount", 2);
        }
      }

      Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (Number(error.response.status) === 401 || Number(error.response.status) === 403) {
          if (!sessionStorage.getItem("notReload") && sessionStorage.getItem("reloadCount") != 1) {
            clearCacheStorage();
            sessionStorage.setItem("reloadCount", 1);
            window.location.reload();
          } else {
            sessionStorage.setItem("reloadCount", 2);
          }
        }
      }

      throw error;
    }
  );
  return apiClient;
};

export default api();
