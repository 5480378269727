import React from "react";
import { ROLE_CONST } from "../constants/role-constants";

export const ROUTER_PAGE = {
  OFFICE_MANAGEMENT: {
    WARNING_SETTING_PAGE: "/office-management/warning-setting-page",
    WARNING_HISTORY_PAGE: "/office-management/warning-history-page",
    WARNING_EVENT_PAGE: "/office-management/warning-event-page",
    DETECT_ABNORMAL_PAGE: "/office-management/detect-abnormal-page",
    WARNING_HISTORY_DETAIL_PAGE:
      "/office-management/warning-history-detail-page",
    DASHBOARD_PAGE: "/office-management/dashboard-page",
    DASHBOARD_OFFICE_PAGE: "/office-management/dashboard-office-page",
    DASHBOARD_SETTING_PAGE: "/office-management/dashboard-setting-page",
    DETECT_ERROR_PAGE: "/office-management/detect-error-page",
    OFFICE_SETTINGS_PAGE: "/office-management/office-settings-page",
  },
  SYSTEM_MANAGEMENT: {
    CAMERA_SETTING_PAGE: "/system-management/camera-setting-page",
    FILE_STORAGE_PAGE: "/system-management/file-storage-page",
    OFFICE_SETTING_PAGE: "/system-management/office-setting-page",
    SYSTEM_SETTING_PAGE: "/system-management/system-setting-page",
    SYSTEM_WARNING_HISTORY_PAGE:
      "/system-management/system-warning-history-page",
    SYSTEM_WARNING_DETAIL_PAGE: "/system-management/system-warning-detail-page",
    PROCESS_PLACE_SETTING_PAGE: "/system-management/process-place-setting-page",
    SENSOR_SETTING_PAGE: "/system-management/sensor-setting-page",
  },
  ACCESS_MANAGEMENT: {
    USER_MANAGEMENT_PAGE: "/access-management/user-management-page",
    ROLE_MANAGEMENT_PAGE: "/access-management/role-management-page",
  },
  ERROR_PAGE: "/error",
  LOGIN_PAGE: "/login",
  NOT_FOUND_PAGE: "/404",
};

export const ROUTER_PAGE_MAPPING = {};
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE] =
  "設定/警報設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_PAGE] =
  "設定/警報履歴";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE] =
  "設定/警報一覧";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ABNORMAL_PAGE] =
  "設定/入力値が正常か異常か判別";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE] =
  "警報一覧/警報詳細";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE] =
  "設定/施設詳細";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE] =
  "設定/施設一覧";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE] =
  "設定/画面構成設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE] =
  "システム管理/カメラ設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE] =
  "システム管理/ストレージデータ監視";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE] =
  "システム管理/事業所設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE] =
  "システム管理/システム設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE] =
  "システム管理/システム監視";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE] =
  "システム管理/システム監視/システム警報詳細";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE] =
  "システム管理/施設設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE] =
  "システム管理/センサー設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE] =
  "アクセス管理/ユーザー設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE] =
  "アクセス管理/役割設定";
// ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ERROR_PAGE] =
//   "精度誤差検知";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE] =
  "設定/通知・表示設定";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.LOGIN_PAGE] = "ユーザー";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.NOT_FOUND_PAGE] = "404";
ROUTER_PAGE_MAPPING[ROUTER_PAGE.ERROR_PAGE] = "Error";

export const REQUEST_ROLE_MAPPING = {};
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE] =
  [ROLE_CONST.P200, ROLE_CONST.P201];
//REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_PAGE] =
  //[ROLE_CONST.P500];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE] =
  [ROLE_CONST.P500];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ABNORMAL_PAGE] =
  [ROLE_CONST.P202];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE] =
  [];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE] =
  [ROLE_CONST.P600];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE] =
  [ROLE_CONST.P600];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE] =
  [ROLE_CONST.P600];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE] =
  [ROLE_CONST.P106, ROLE_CONST.P107];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE] =
  [ROLE_CONST.P302];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE] =
  [ROLE_CONST.P102, ROLE_CONST.P103];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE] =
  [ROLE_CONST.P101];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE] =
  [ROLE_CONST.P300];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE] =
  [];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE] =
  [ROLE_CONST.P104, ROLE_CONST.P105];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE] =
  [ROLE_CONST.P104, ROLE_CONST.P105];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE] =
  [ROLE_CONST.P108, ROLE_CONST.P109];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE] =
  [ROLE_CONST.P110, ROLE_CONST.P111];
REQUEST_ROLE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE] =
  [ROLE_CONST.P112];

export default {
  ROUTER_PAGE: ROUTER_PAGE,
  ROUTER_PAGE_MAPPING: ROUTER_PAGE_MAPPING,
  REQUEST_ROLE_MAPPING: REQUEST_ROLE_MAPPING
};
