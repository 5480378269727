import apiClient from "./api";
import { loadingPage } from "../loading";

const officeApi = {
  // define call api for screen config
  getDashboardOffice: async (params, dispatch) => {
    try {
      const { data, isSearch } = params;
      if (!params.noLoading) {
        dispatch(loadingPage(true));
      }
      const url = "/dashboard/factory";
      const response = await apiClient.post(url, data);
      if ((response.data && Number(response.data.code) === 0) || !isSearch) {
        if (!params.noLoading) {
          dispatch(loadingPage(false));
        }
        return response.data;
      }
      if (isSearch) {
        throw new Error(response.data.message || "内部サーバーエラー");
      }
    } catch (error) {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      throw error;
    }
  },
};

export default officeApi;
