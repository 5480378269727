import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import apiClient from "../../services/api";
import { loadingPage } from "../../loading";

import * as TYPES from "./type";

const getLogsAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/actionLog/search", params.data);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      return response.data ? response.data : { data: [] };
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
    return { data: [] };
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

export const getLogs = createAsyncThunk(TYPES.GET_LOGS, getLogsAsync);
