export const convertObjectToArray = (dataObject) => {
  const convertArray = [];
  for (let key in dataObject) {
    if (dataObject.hasOwnProperty(key)) {
      convertArray.push({
        value: Number(key),
        label: dataObject[key],
      });
    }
  }
  return convertArray;
};
