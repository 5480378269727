import { createSlice } from "@reduxjs/toolkit";
import { getNotify, updateReadNotify, updateListReadNotify } from "./action";
import { get } from "lodash";
const initialState = {
  notifyData: {
    numberUnread: 0,
    notifyList: [],
  },
  error: {},
  isLoading: false,
};

const notifySlice = createSlice({
  name: "notify",
  initialState,
  extraReducers: {
    [getNotify.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getNotify.fulfilled]: (state, action) => {
      state.isLoading = false;
      let notification = get(state, "notifyData", {
        numberUnread: 0,
        notifyList: [],
      });
      if (action.payload.isReadAll) {
        notification.notifyList = [...action.payload.listNotify];
      } else if (action.payload.isGetNotifyInterval) {
        notification.notifyList =  get(action.payload, "listNotify", []);
      } else {
        notification.notifyList.push(...action.payload.listNotify);
      }
      notification.numberUnread = action.payload.numberUnread;
      state.notifyData = notification;
    },
    [getNotify.rejected]: (state, action) => {
      state.notifyData = {};
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateReadNotify.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateReadNotify.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateReadNotify.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateListReadNotify.pending]: (state, action) => {
      state.isLoading = true;
    },
    [updateListReadNotify.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [updateListReadNotify.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
  reducers: {
    setNotify: (state, action) => {
      state.notifyData = {
        numberUnread: 0,
        notifyList: [],
      };
    },
  },
});

//the action
export const { setNotify } = notifySlice.actions;
//the selector
export const selectorNotify = (state) => state.notify.notifyData;

// the reducer
export default notifySlice.reducer;
