import React, { useEffect, useState } from "react";
import "./App.scss";
import "./styles/css/custom.scss";
import { Layout, Modal, Spin } from "antd";
import Router from "./routers/router.jsx";
import { HashRouter, useHistory } from "react-router-dom";
import AppLoading from "./component/Util/AppLoading";
import { setError } from "./redux/error";
import { useTranslation } from "react-i18next";
import { IdleTimeoutManager } from "idle-timer-manager";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  history.listen(() => {
    Modal.destroyAll();
  });

  return (
    <div className="App">
      <HashRouter>
        <Layout>
          <Router />
        </Layout>
      </HashRouter>
      <AppLoading />
    </div>
  );
}

export default App;
