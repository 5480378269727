import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import { getCameras, createOrUpdateCamera, getCameraHistories } from "./action";

const initialState = {
  cameraList: {
    data: [],
    error: {},
    isLoading: false,
  },
  createOrUpdateCamera: {
    data: [],
    error: {},
    isLoading: false,
  },
  cameraHistoriesList: {
    data: [],
    error: {},
    isLoading: false,
  },
};

const cameraSlice = createSlice({
  name: "cameras",
  initialState,
  extraReducers: {
    // get list cameras
    [getCameras.pending]: (state, action) => {
      state.cameraList.isLoading = true;
    },
    [getCameras.fulfilled]: (state, action) => {
      state.cameraList.isLoading = false;
      state.cameraList.data = get(action, "payload", []);
    },
    [getCameras.rejected]: (state, action) => {
      state.cameraList.isLoading = false;
      state.cameraList.data = [];
      state.cameraList.error = action.payload;
    },
    // create or update camera
    [createOrUpdateCamera.pending]: (state, action) => {
      state.createOrUpdateCamera.isLoading = true;
    },
    [createOrUpdateCamera.fulfilled]: (state, action) => {
      state.createOrUpdateCamera.isLoading = false;
      state.createOrUpdateCamera.data = action.payload;
    },
    [createOrUpdateCamera.rejected]: (state, action) => {
      state.createOrUpdateCamera.isLoading = false;
      state.createOrUpdateCamera.data = [];
      state.createOrUpdateCamera.error = action.payload;
    },
    // get list camera histories
    [getCameraHistories.pending]: (state, action) => {
      state.cameraHistoriesList.isLoading = true;
    },
    [getCameraHistories.fulfilled]: (state, action) => {
      state.cameraHistoriesList.isLoading = false;
      state.cameraHistoriesList.data = get(action, "payload", []);
    },
    [getCameraHistories.rejected]: (state, action) => {
      state.cameraHistoriesList.isLoading = false;
      state.cameraHistoriesList.data = [];
      state.cameraHistoriesList.error = action.payload;
    },
  },
  reducers: {},
});

//the selector
export const cameraSelector = (state) => state.users;

export const selectorCameraHistories = (state) =>
  state.cameras.cameraHistoriesList.data;
export const selectorCameras = (state) => state.cameras.cameraList.data;

// the reducer
export default cameraSlice.reducer;
