import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import apiClient from "../../services/api";
import { loadingPage } from "../../loading";

import * as TYPES from "./type";

const getWarningsAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/warnConfig/search", params.data);
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getWarningConfigsAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.get(
      `/systemConfig/getFactoryConfig?factoryId=${params}`
    );
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getDashboardInfoAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post("/dashboard/factory", params.data);
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getDashboardWarnEventsAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/warn/getDashboardWarnEvent",
      params.data
    );
    if (response.data && Number(response.data.code) === 0) {
      if (params && params.callback) {
        params.callback(response.data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return response.data ? response.data : { data: [] };
    }
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    if (params.isSearch) {
      notification.error({
        message: response.data.message || "内部サーバーエラー",
      });
    }
  } catch (error) {
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const createOrUpdateWarningAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/warnConfig/createOrUpdate",
      params.data
    );
    const data = response.data;
    if (Number(data.code) === 0) {
      if (params && params.callback) {
        params.callback(data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    } else {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      notification.error({
        message: data.message ? data.message : "内部サーバーエラー",
      });
    }
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const getListOperationGuidanceManualAsync = async (
  params = {},
  { dispatch }
) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/warn/getListOperationGuidanceManual",
      params.data
    );
    const data = response.data;
    if (Number(data.code) === 0) {
      if (params && params.callback) {
        params.callback(data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    } else {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      notification.error({
        message: data.message ? data.message : "内部サーバーエラー",
      });
    }
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

const updateOperationGuidanceManualAsync = async (params = {}, { dispatch }) => {
  try {
    if (!params.noLoading) {
      dispatch(loadingPage(true));
    }
    const response = await apiClient.post(
      "/warn/updateOperationGuidanceManual",
      params.data
    );
    const data = response.data;
    if (Number(data.code) === 0) {
      if (params && params.callback) {
        params.callback(data);
      }
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      return data;
    } else {
      if (!params.noLoading) {
        dispatch(loadingPage(false));
      }
      notification.error({
        message: data.message ? data.message : "内部サーバーエラー",
      });
    }
  } catch (error) {
    console.log(error);
    if (!params.noLoading) {
      dispatch(loadingPage(false));
    }
    notification.error({
      message: "内部サーバーエラー",
    });
  }
};

export const getWarnings = createAsyncThunk(
  TYPES.GET_WARNINGS,
  getWarningsAsync
);

export const getWarningConfigs = createAsyncThunk(
  TYPES.GET_WARNING_CONFIGS,
  getWarningConfigsAsync
);

export const getDashboardWarnEvents = createAsyncThunk(
  TYPES.GET_DASHBOARD_WARN_EVENTS,
  getDashboardWarnEventsAsync
);

export const getDashboardInfo = createAsyncThunk(
  TYPES.GET_DASHBOARD_INFO,
  getDashboardInfoAsync
);

export const createOrUpdateWarning = createAsyncThunk(
  TYPES.CREATE_OR_UPDATE_WARNING,
  createOrUpdateWarningAsync
);

export const getListOperationGuidanceManual = createAsyncThunk(
  TYPES.GET_OPERATION_GUIDANCE_MANUALS,
  getListOperationGuidanceManualAsync
);

export const updateOperationGuidanceManual = createAsyncThunk(
  TYPES.UPDATE_OPERATION_GUIDANCE_MANUALs,
  updateOperationGuidanceManualAsync
);
