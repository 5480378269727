import { useSelector } from "react-redux";
import {
  ROUTER_PAGE,
  ROUTER_PAGE_MAPPING,
  REQUEST_ROLE_MAPPING
} from "../constants/router-constants";

export const checkRole = (role, p) => {
  const auth = useSelector((state) => state.auth);
  if (!role && auth) {
    role = auth.role;
  }
  if (role != null && role.includes(p)) {
    return true;
  }
  return false;
};

export const checkListRole = (role, pArr) => {
  const auth = useSelector((state) => state.auth);
  if (!role && auth) {
    role = auth.role;
  }

  if (role != null) {
    for (let i = 0; i < pArr.length; i++) {
      if (role.includes(pArr[i])) {
        return true;
      }
    }
  }
  return false;
};

export const checkRequestRole = (role, request) => {
  let dataRole = null;
  for (let key in REQUEST_ROLE_MAPPING) {
    if (request && request.indexOf(key) != -1) {
      dataRole = REQUEST_ROLE_MAPPING[key];
      break;
    }
  }
  if (!dataRole || !role || role.length == 0) {
    return false;
  }
  if (dataRole.length == 0) {
    return true;
  } else {
    for (let i = 0; i < dataRole.length; i++) {
      if (role.includes(dataRole[i])) {
        return true;
      }
    }
  }
  return false;
}
