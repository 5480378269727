import React from "react";
import { getLogOutUrl} from "../../redux/services/config";

function LogOutPage() {
  const urlNavigate = getLogOutUrl();
  for( let k in window.localStorage) {
    if( k !== "dasboardSelected") {
      delete window.localStorage[k];
    }
  }
  window.location.replace(urlNavigate);
  return <div />;
}

export default LogOutPage;
