import { createSlice } from "@reduxjs/toolkit";
import { getDashboardOffice } from "./action";
const initialState = {
  dashboardOffice: {
    dashboardList: [],
    error: {},
    isLoading: false,
  },
};

const officeSlice = createSlice({
  name: "offices",
  initialState,
  extraReducers: {
    // reducer creator for dashboard screen
    [getDashboardOffice.pending]: (state, action) => {
      state.dashboardOffice.isLoading = true;
    },
    [getDashboardOffice.fulfilled]: (state, action) => {
      state.dashboardOffice.isLoading = false;
      state.dashboardOffice.dashboardList = action.payload;
    },
    [getDashboardOffice.rejected]: (state, action) => {
      state.dashboardOffice.dashboardList = [];
      state.dashboardOffice.isLoading = false;
      state.dashboardOffice.error = action.payload;
    },
  },
  reducers: {},
});

//the selector
export const selectorDashboard = (state) =>
  state.offices.dashboardOffice.dashboardList;

// the reducer
export default officeSlice.reducer;
