import { createAsyncThunk } from "@reduxjs/toolkit";
import systemApi from "../../services/system";
import { loadingPage } from "../../loading";
import {
  GET_SCREEN_CFG,
  APPLY_SCREEN_CFG,
  GET_OFFICE,
  CREATE_OR_UPDATE_OFFICE,
  GET_SENSOR,
  CREATE_OR_UPDATE_SENSOR,
  GET_PROCESS_PLACE,
  CREATE_OR_UPDATE_PROCESS_PLACE,
  GET_WARN_EVENT,
  GET_WARN_HISTORY,
  GET_WARN_HISTORY_DETAIL,
  UPDATE_WARN_STATUS,
  GET_FACTORY_CONFIG,
  CREATE_FACTORY_CONFIG,
  GET_SYSTEM_CONFIG,
  CREATE_SYSTEM_CONFIG,
  GET_WARN_TYPE_CONFIG,
  GET_WARN_LEVEL_CONFIG,
  GET_LIST_PREDICTION_SENSOR,
  GET_LIST_ACTION_LOG,
  GET_LIST_ACTION_LOG_DETAIL,
  GET_SIGNED_URL,
  GET_FILE_STORAGE,
  DELETE_FILE_STORAGE,
  DOWNLOAD_FILE_STORAGE,
  GET_STORAGE_FILE_TYPE_CONFIG,
} from "./type";

const getScreenCfgAsync = async (params, { dispatch }) => {
  if (!params.noLoading) {
    dispatch(loadingPage(true));
  }
  const result = await systemApi.getScreenCfg(params.data);
  const screenCfgDataList = result.code === "0" ? result.data : [];
  if (!params.noLoading) {
    dispatch(loadingPage(false));
  }
  return screenCfgDataList;
};

const applyScreenCfgAsync = async (params, { dispatch }) => {
  if (!params.noLoading) {
    dispatch(loadingPage(true));
  }
  const result = await systemApi.applyScreenCfg(params);
  if (!params.noLoading) {
    dispatch(loadingPage(false));
  }
  return result;
};

const getOfficeAsync = async (params, { dispatch }) => {
  const result = await systemApi.getOffice(params, dispatch);
  const officeDataList = result && result.code === "0" ? result.data : [];
  officeDataList.forEach((officeInfo, index) => {
    officeInfo["key"] = index;
    officeInfo["name_option"] = officeInfo.factoryName;
    officeInfo["id_option"] = officeInfo.factoryId;
  });
  return officeDataList;
};

const createOrUpdateOfficeAsync = async (params, { dispatch }) => {
  const result = await systemApi.createOrUpdateOffice(params, dispatch);
  return result;
};

const getSensorAsync = async (params, { dispatch }) => {
  const result = await systemApi.getSensor(params, dispatch);
  const sensorDataList = result && result.code === "0" ? result.data : [];
  sensorDataList.forEach((sensorInfo, index) => {
    sensorInfo["key"] = index;
    sensorInfo["name_option"] = sensorInfo.name;
    sensorInfo["id_option"] = sensorInfo.deviceId;
  });
  return sensorDataList;
};

const createOrUpdateSensorAsync = async (params, { dispatch }) => {
  const result = await systemApi.createOrUpdateSensor(params, dispatch);
  return result;
};

const getProcessPlaceAsync = async (params, { dispatch }) => {
  const result = await systemApi.getProcessPlace(params, dispatch);
  const processDataList = result && result.code === "0" ? result.data : [];
  processDataList.forEach((processInfo, index) => {
    processInfo["key"] = index;
    processInfo["name_option"] = processInfo.name;
    processInfo["id_option"] = processInfo.processPlaceId;
  });
  return processDataList;
};

const createOrUpdateProcessAsync = async (params, { dispatch }) => {
  const result = await systemApi.createOrUpdateProcess(params, dispatch);
  return result;
};

const getWarnEventAsync = async (params, { dispatch }) => {
  const result = await systemApi.getWarnEvent(params, dispatch);
  const eventDataList = result && result.code === "0" ? result.data : [];
  const totalRecords =result.totalRecords;
  eventDataList.forEach((eventInfo, index) => {
    eventInfo["key"] = index;
  });
  return {eventDataList, totalRecords};
};

const updateWarnStatusAsync = async (params, { dispatch }) => {
  const result = await systemApi.updateWarnStatus(params, dispatch);
  return result;
};

const getWarnHistoryAsync = async (params, { dispatch }) => {
  const result = await systemApi.getWarnHistory(params, dispatch);
  const historyDataList = result && result.code === "0" ? result.data : [];
  historyDataList.forEach((historyInfo, index) => {
    historyInfo["key"] = index;
  });
  return historyDataList;
};

const getWarnHistoryDetailAsync = async (params, { dispatch }) => {
  const result = await systemApi.getWarnHistoryDetail(params, dispatch);
  const historyDetailData =
    result && result.code === "0"
      ? result.data
      : {
          warnEvent: {},
          listWarnHis: [],
        };
  historyDetailData.listWarnHis.forEach((historyDetailInfo, index) => {
    historyDetailInfo["key"] = index;
  });
  return historyDetailData;
};

const getFactoryConfigAsync = async (params, { dispatch }) => {
  const result = await systemApi.getFactoryConfig(params, dispatch);
  const factoryConfigData =
    result && result.code === "0"
      ? result.data
      : {
          listWarnLevel: [],
          listEmail: [],
        };
  factoryConfigData.listWarnLevel.forEach((warnLevelInfo, index) => {
    warnLevelInfo["key"] = index;
  });
  return factoryConfigData;
};

const createFactoryConfigAsync = async (params, { dispatch }) => {
  const result = await systemApi.createFactoryConfig(params, dispatch);
  return result;
};

const getSystemConfigAsync = async (params, { dispatch }) => {
  const result = await systemApi.getSystemConfig(params, dispatch);
  const systemConfigData =
    result && result.code === "0"
      ? result.data
      : {
          listEmail: [],
          listPredictionSensor: [],
        };
  systemConfigData.listPredictionSensor.forEach(
    (predictionSensorInfo, index) => {
      predictionSensorInfo["key"] = index;
    }
  );
  return systemConfigData;
};

const createSystemConfigAsync = async (params, { dispatch }) => {
  const result = await systemApi.createSystemConfig(params, dispatch);
  return result;
};

const getWarnTypeConfigAsync = async (params, { dispatch }) => {
  const result = await systemApi.getWarnTypeConfig(params, dispatch);
  const warnTypeConfigData = result && result.code === "0" ? result.data : [];
  warnTypeConfigData.forEach((configInfo, index) => {
    configInfo["key"] = index;
    configInfo["name_option"] = configInfo.name;
    configInfo["id_option"] = configInfo.value;
  });
  return warnTypeConfigData;
};

const getWarnLevelConfigAsync = async (params, { dispatch }) => {
  const result = await systemApi.getWarnLevelConfig(params, dispatch);
  const warnLevelConfigData = result && result.code === "0" ? result.data : [];
  warnLevelConfigData.forEach((configInfo, index) => {
    configInfo["key"] = index;
    configInfo.label = configInfo.name;
  });
  return warnLevelConfigData;
};

const getListPredictionSensorAsync = async (params, { dispatch }) => {
  const result = await systemApi.getListPredictionSensor(params, dispatch);
  const predictionSensorList = result && result.code === "0" ? result.data : [];
  predictionSensorList.forEach((predictionSensor, index) => {
    predictionSensor["key"] = index;
  });
  return predictionSensorList;
};

const getListActionLogAsync = async (params, { dispatch }) => {
  const result = await systemApi.getListActionLog(params, dispatch);
  const actionLogList = result && result.code === "0" ? result.data : [];
  const totalRecords = result.totalRecords;
  actionLogList.forEach((actionLog, index) => {
    actionLog["key"] = index;
  });
  return {actionLogList, totalRecords};
};

const getListActionLogDetailAsync = async (params, { dispatch }) => {
  const result = await systemApi.getListActionLogDetail(params, dispatch);
  const actionLogDetailList = result && result.code === "0" ? result.data : [];
  actionLogDetailList.forEach((actionLogDetail, index) => {
    actionLogDetail["key"] = index;
  });
  return actionLogDetailList;
};

const getSignedUrlAsync = async (params, { dispatch }) => {
  const result = await systemApi.getSignedUrl(params, dispatch);
  const signedUrl = result && result.code === "0" ? result.data : "";
  return signedUrl;
};

const getFileStorageAsync = async (params, { dispatch }) => {
  const result = await systemApi.getFileStorage(params, dispatch);
  const fileStorageData =
    result && result.code === "0"
      ? result.data
      : 
      { 
        fileStorageHistoryDTOList: [],
        storageFileCount: result.data.storageFileCount,
        storageFileSize: result.data.storageFileSize,
        storageFileSizeText: result.data.storageFileSizeText,
        gridFileCount: result.data.gridFileCount,
        gridFileSize: result.data.gridFileSize,
        gridFileSizeText: result.data.gridFileSizeText,
        message: result.message,
        totalRecords: result.totalRecords
      };
  fileStorageData.fileStorageHistoryDTOList.forEach((fileInfo, index) => {
    fileInfo["key"] = fileInfo.id;
  });
  return fileStorageData;
};

// const downloadFileStorageAsync = async (params, { dispatch }) => {
//   const result = await systemApi.downloadFileStorage(params, dispatch);
//   return result;
// };

const deleteFileStorageAsync = async (params, { dispatch }) => {
  const result = await systemApi.deleteFileStorage(params, dispatch);
  return result;
};

const getStorageFileTypeConfigAsync = async (params, { dispatch }) => {
  const result = await systemApi.getStorageFileTypeConfig(params, dispatch);
  const storageFileTypeConfigData = result && result.code === "0" ? result.data : [];
  storageFileTypeConfigData.forEach((configInfo, index) => {
    configInfo["key"] = index;
    configInfo.label = configInfo.name;
    configInfo["name_option"] = configInfo.name;
    configInfo["id_option"] = configInfo.value;
  });
  return storageFileTypeConfigData;
};

// action creator for screen config
export const getScreenCfg = createAsyncThunk(GET_SCREEN_CFG, getScreenCfgAsync);
export const applyScreenCfg = createAsyncThunk(
  APPLY_SCREEN_CFG,
  applyScreenCfgAsync
);
// action creator for office
export const getOffice = createAsyncThunk(GET_OFFICE, getOfficeAsync);
export const createOrUpdateOffice = createAsyncThunk(
  CREATE_OR_UPDATE_OFFICE,
  createOrUpdateOfficeAsync
);
// action creator for sensor
export const getSensor = createAsyncThunk(GET_SENSOR, getSensorAsync);
export const createOrUpdateSensor = createAsyncThunk(
  CREATE_OR_UPDATE_SENSOR,
  createOrUpdateSensorAsync
);

//action creator for process place
export const getProcessPlace = createAsyncThunk(
  GET_PROCESS_PLACE,
  getProcessPlaceAsync
);
export const createOrUpdateProcess = createAsyncThunk(
  CREATE_OR_UPDATE_PROCESS_PLACE,
  createOrUpdateProcessAsync
);

//action creator for warn event
export const getWarnEvent = createAsyncThunk(GET_WARN_EVENT, getWarnEventAsync);
export const updateWarnStatus = createAsyncThunk(
  UPDATE_WARN_STATUS,
  updateWarnStatusAsync
);

//action creator for warn history
export const getWarnHistory = createAsyncThunk(
  GET_WARN_HISTORY,
  getWarnHistoryAsync
);

//action creator for warn history detail
export const getWarnHistoryDetail = createAsyncThunk(
  GET_WARN_HISTORY_DETAIL,
  getWarnHistoryDetailAsync
);

//action creator for factory config
export const getFactoryConfig = createAsyncThunk(
  GET_FACTORY_CONFIG,
  getFactoryConfigAsync
);

export const createFactoryConfig = createAsyncThunk(
  CREATE_FACTORY_CONFIG,
  createFactoryConfigAsync
);

//action creator for system config
export const getSystemConfig = createAsyncThunk(
  GET_SYSTEM_CONFIG,
  getSystemConfigAsync
);

export const createSystemConfig = createAsyncThunk(
  CREATE_SYSTEM_CONFIG,
  createSystemConfigAsync
);

export const getWarnTypeConfig = createAsyncThunk(
  GET_WARN_TYPE_CONFIG,
  getWarnTypeConfigAsync
);

export const getWarnLevelConfig = createAsyncThunk(
  GET_WARN_LEVEL_CONFIG,
  getWarnLevelConfigAsync
);

//action creator for Prediction Sensor List
export const getListPredictionSensor = createAsyncThunk(
  GET_LIST_PREDICTION_SENSOR,
  getListPredictionSensorAsync
);

//action creator for action log
export const getListActionLog = createAsyncThunk(
  GET_LIST_ACTION_LOG,
  getListActionLogAsync
);

export const getListActionLogDetail = createAsyncThunk(
  GET_LIST_ACTION_LOG_DETAIL,
  getListActionLogDetailAsync
);

//action creator for azure signed url
export const getSignedUrl = createAsyncThunk(GET_SIGNED_URL, getSignedUrlAsync);

//action creator for file storage
export const getFileStorage = createAsyncThunk(
  GET_FILE_STORAGE,
  getFileStorageAsync
);
// export const downloadFileStorage = createAsyncThunk(GET_FILE_STORAGE, downloadFileStorageAsync);
export const deleteFileStorage = createAsyncThunk(DELETE_FILE_STORAGE, deleteFileStorageAsync);

export const getStorageFileTypeConfig = createAsyncThunk(
  GET_STORAGE_FILE_TYPE_CONFIG,
  getStorageFileTypeConfigAsync
);
