import React, {useState} from "react";
import { Row, Col, Button, Card } from "antd";
import { useSelector } from "react-redux";
import { clearCacheStorage } from "../../utils/dealCacheData";
import { getLogOutUrl } from "../../redux/services/config";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../component/button/button";
import { SIZE_BUTTON } from "../../constants/common";

function ErrorPage() {
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.error);
  const history = useHistory();

  const logoutUrl = getLogOutUrl();
  clearCacheStorage();
  const redirectLoginPage = () => {
    history.push("/login");
    window.location.reload();
  };

  const [objectError, setObjectError] = useState({
    message: error.message ? error.message : t("messages.unexpected_error"),
    actionLabel: error.actionLabel ? error.actionLabel : t("commons.go_to_login"),
    callback: error.callback ? error.callback : redirectLoginPage
  })
  return (
    <Row
      className=""
      align="middle"
      justify="center"
      gutter={[16, 16]}
      style={{ marginTop: 10, marginBottom: 10 }}
    >
      <Col span={4}><iframe
          style={{ width: 0, height: 0, position: 'absolute', left: 0, top: 0, display: 'none', visibility: 'hidden' }}
          width={0}
          height={0}
          title="silent-logout"
          src={logoutUrl}
          // onLoad={localStorage.clear()}
      /></Col>
      <Col span={16} style={{ textAlign: "center" }}>
        <Card className="card_search" style={{ width: "100%", marginTop: 20 }}>
          <Row align="center">
            <Col span={24} style={{ marginTop: 20 }}>
              <div dangerouslySetInnerHTML={{__html: objectError.message ? objectError.message : ""}}></div>
            </Col>
            <Col span={24} style={{ marginTop: 20, display: "flex", justifyContent: "center"}}>
              <ButtonComponent
                handleClick={() => {
                  // if (objectError && objectError.callback) {
                  //   objectError.callback();
                  // }
                  window.location.href = logoutUrl;
                }}
                className="btn-primary"
                label={objectError && objectError.actionLabel ? objectError.actionLabel : ""}
                size={SIZE_BUTTON.medium}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col span={4}></Col>
    </Row>
  );
}
export default ErrorPage;
