import React, { useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import {  Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./directory.scss";
import {ROUTER_PAGE_MAPPING, ROUTER_PAGE} from "../../constants/router-constants";
import { selectorOfficeOwnUser } from "../../redux/auth";
import { removeDashboardPageKeyStorage } from "../../utils/dealCacheData";
const ROUTER_WITHOUT_PARENT_PATH= [
    ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE
];
const ROUTER_WITH_SPECIFIED_OFFICE = [
    ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE,
    ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE,
    ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ERROR_PAGE,
]
const ROUTER_WITH_ALL_OFFICE = [
   ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE,
   ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE,
   ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE,
   ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE,
   ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE,
   ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE,
   ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE,
   ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE,
   ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE,
]

const ROUTERS_ONLY_OPTION_ALL = [
    ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE,
    ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE,
  ];

const PARENT_PAGE_WITH_LINK = [
    ROUTER_PAGE_MAPPING[ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE].split("/")[0],
    ROUTER_PAGE_MAPPING[ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE].split("/")[1]
]

function DirectoryPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const officeListHeader = useSelector(selectorOfficeOwnUser) || [];
  const [pathname, setPathname] = useState(["", ""]);
  const [officeName, setOfficeName] = useState("");
  const  [officeSelected, setOfficeSelected] = useState(null);
  const { role, user } = useSelector((state) => state.auth);
  const handleChangeRouter = (pathname) => {
    let urlPath =  pathname.replace(/\/?\-?\d+/g, "");
    if (urlPath.includes("/null")) {
        urlPath = urlPath.slice(0, -5);
      }
    let arrPath = ROUTER_PAGE_MAPPING[urlPath].split("/");
    const  officeIdUrl = history.location.pathname.match(/\-?\d+/g) ? Number(history.location.pathname.match(/\-?\d+/g)[0]) : null;
    for (let office of officeListHeader) {
        if (office && office.value == officeIdUrl) {
            setOfficeName(() => office.label);
            setPathname(() => arrPath);
            setOfficeSelected(officeIdUrl);
            // setOfficeName(() => "");
        } else {
            setOfficeSelected(officeIdUrl);
            setPathname(() => arrPath);
        } 
        if (ROUTERS_ONLY_OPTION_ALL.indexOf(urlPath) != -1) {
            setOfficeName(() => "全事業所")
        }
        // if (officeIdUrl == -1) {
        //     const officeIdFistOfArray = officeListHeader.length > 0 ? (ROUTER_WITH_ALL_OFFICE.indexOf(urlPath) != -1 ? officeListHeader[1].value : officeListHeader[0].value) : officeSelected; 
        //     const userFactoryId = user.userFactoryId ?  user.userFactoryId : officeIdFistOfArray;
        //     let officeIdDashboard  = localStorage.getItem(ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE) || userFactoryId;
        //     setOfficeSelected(() => officeIdDashboard);
        //     setPathname(() => arrPath);
        // }
    }
 }

  useEffect(() => {
    handleChangeRouter(history.location.pathname);
  }, [officeListHeader]);

  history.listen((location) => {
    handleChangeRouter(location.pathname);
  })

  const renderBreadcrumb = () => {
    let urlPath =  history.location.pathname.replace(/\/?\-?\d+/g, "");
    let contentBreadcrumb = "";
    if (ROUTER_WITHOUT_PARENT_PATH.indexOf(urlPath) != -1) {
        contentBreadcrumb = (
            <>
                <span className="seperator">
                </span>
                <span className="current-path link-name">
                    {pathname[1]}
                </span>
            </>
        )
    } else if (ROUTER_WITH_ALL_OFFICE.indexOf(urlPath) != -1 || ROUTER_WITH_SPECIFIED_OFFICE.indexOf(urlPath) != -1) {
        if (pathname.length == 2) {
            contentBreadcrumb = (
                <>
                    <span className="seperator">
                    </span>
                    <span className={`parent-path link-name 
                        ${PARENT_PAGE_WITH_LINK.indexOf(pathname[0]) == 0 ? "parent-page-link" : ""}`} 
                        onClick={() => {
                            if (PARENT_PAGE_WITH_LINK.indexOf(pathname[0]) == 0) {
                                history.push({
                                    pathname: `${ROUTER_PAGE.OFFICE_MANAGEMENT
                                        .WARNING_EVENT_PAGE}/${officeSelected}`,
                                    state: {
                                        previousUrl: history.location.pathname
                                    }
                                })
                            }
                        }}>
                        {pathname[0]}
                    </span>
                    <span className="seperator">
                    </span>
                    <span className="current-path link-name">
                        {pathname[1]}
                    </span>
                </>
            )
        } else if (pathname.length == 3) {
            contentBreadcrumb = (
                <>
                    <span className="seperator">
                    </span>
                    <span className="parent-path link-name">
                        {pathname[0]}
                    </span>
                    <span className="seperator">
                    </span>
                    <span className={`parent-path link-name 
                        ${PARENT_PAGE_WITH_LINK.indexOf(pathname[1]) == 1 ? "parent-page-link" : ""}`} 
                        onClick={() => {
                            if (PARENT_PAGE_WITH_LINK.indexOf(pathname[1]) == 1) {
                                history.push({
                                    pathname: `${ROUTER_PAGE.SYSTEM_MANAGEMENT
                                        .SYSTEM_WARNING_HISTORY_PAGE}/${officeSelected}`,
                                    state: {
                                        previousUrl: history.location.pathname
                                    }
                                })
                            }
                        }}>
                        {pathname[1]}
                    </span>
                    <span className="seperator">
                    </span>
                    <span className="current-path link-name">
                        {pathname[2]}
                    </span>
                </>
            )
        }

    }

    return  contentBreadcrumb;
  }

  const renderTitlePage = () => {
      if (pathname.length == 2) {
          return pathname[1];
      } else if (pathname.length == 3) {
          return pathname[2];
      }
      return "";
  }

  return (
    <Row className="directory-page">
        <Col className="title-page" span={12}>
            {renderTitlePage()}
        </Col>
        <Col span={12} style={{}}>
            <div className="directory">
                <span className={`link-name ${officeName == "全事業所" ? "disable-link" : "office-name"}`} onClick={() => {
                    if ( officeName != "全事業所") {
                        // removeDashboardPageKeyStorage();
                        history.push(`${ROUTER_PAGE.OFFICE_MANAGEMENT
                            .DASHBOARD_PAGE}/${officeSelected}`
                        )
                    }
                }}>
                    {officeName}
                </span>
                {renderBreadcrumb()}
            </div>
        </Col>
    </Row>
  );
}

export default DirectoryPage;
