import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Spin, notification, Typography, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import "./header.scss";
import ROUTER_PAGE from "../../constants/router-constants";
import RecoveryIcon from "@src/styles/icon/alert_0.svg";
import Warning1Icon from "@src/styles/icon/alert_1.svg";
import Warning2Icon from "@src/styles/icon/alert_2.svg";
import Warning3Icon from "@src/styles/icon/alert_3.svg";
import Warning4Icon from "@src/styles/icon/alert_4.svg";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getNotify,
  updateReadNotify,
} from "../../redux/modules/notification/action";
import {
  selectorNotify,
  setNotify,
} from "../../redux/modules/notification/reducer";
import { DATE_FORMAT } from "../../constants/common";
import { SYSTEM_WARNING_DETAIL_NOTYFYS, WARNING_HISTORY_DETAIL_NOTYFYS } from "../../constants/notify";
import moment from "moment";
import { unwrapResult } from "@reduxjs/toolkit";

function Notification({ hideNotify, loadingPopover }) {
  const history = useHistory();
  const dispatch = useDispatch();
  let notifyData = useSelector(selectorNotify) || { notifyList: [] };
  let { notifyList } = notifyData;
  const { user } = useSelector((state) => state.auth);
  let [hasMore, setHasMore] = useState(true);
  let [loading, setLoading] = useState(loadingPopover);
  let [page, setPage] = useState(0);
  const notifyRef = useRef(null);
  const [isScroll, setIsScroll] = useState(false);
  const { Paragraph } = Typography;
  const officeIdParam = localStorage.getItem("officeSelected");

  const viewNotifyDetail = async (notifyItem) => {
    try {
      const callback = (res) => {
        if (WARNING_HISTORY_DETAIL_NOTYFYS.indexOf(notifyItem.notifyType) != -1) {
          history.push({
            pathname:
              `${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT
                .WARNING_HISTORY_DETAIL_PAGE}/${officeIdParam}`,
            search: `?warnEventId=${notifyItem.warnEventId}&notifyId=${notifyItem.notifyId}`
          });
        } else if (SYSTEM_WARNING_DETAIL_NOTYFYS.indexOf(notifyItem.notifyType) != -1){
          history.push({
            pathname:
              `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT
                .SYSTEM_WARNING_DETAIL_PAGE}/${officeIdParam}`,
            search: `?actionLogId=${notifyItem.actionLogId}&notifyId=${notifyItem.notifyId}`,
          });
        }
      }
      const actionResult = await dispatch(
        updateReadNotify({ data: { notifyId: notifyItem.notifyId },  callback })
      );
      const officeResult = unwrapResult(actionResult);
      // dispatch(setNotify());
      dispatch(getNotify({ data: { offset: 0, limit: notifyList.length }, isReadAll: true}));
      if (hideNotify) {
        hideNotify();
      }
    } catch (error) {
      notification.error({
        message: error.message || "internet server error",
      });
    }
  }

  const fetchMoreData = async () => {
    if (isScroll) {
      if (notifyRef.current) {
        clearTimeout(notifyRef.current);
      }
      setLoading(true);
      setHasMore(false);
      const callback = (res) => {
        setLoading(false);
        setHasMore(true);
        setIsScroll(false);
        notifyRef.current = null;
      };
      notifyRef.current = setTimeout(() => {
        dispatch(
          getNotify({
            data: {
              factoryId: user.userFactoryId,
              userCode: user.userCode,
              offset: notifyList.length,
              limit: 100,
            },
            callback,
          })
        );
      }, 1000);
    }
  };

  const handleEventScrollNotify = () => {
    setIsScroll(true);
  }

  useEffect(() => {
    document.querySelector("#scrollableDiv") && document.querySelector("#scrollableDiv").addEventListener("scroll", handleEventScrollNotify)
    return () => {
      document.querySelector("#scrollableDiv") && document.querySelector("#scrollableDiv").removeEventListener("scroll", handleEventScrollNotify)
    }
  }, [])

  const renderNotifyLayout = () => {
    const notifyInfos = [];
    if (notifyList && notifyList.length > 0) {
      for (let i = 0; i < notifyList.length; i++) {
        notifyInfos.push(
          <Row
            key={i}
            style={notifyList[i].status ? "" : { backgroundColor: "#d0edff" }}
            onClick={() => viewNotifyDetail(notifyList[i])}
            className="rowNotMargin notify"
            align="middle"
          >
            <Col span={22}>
              <Row className="rowNotMargin" align="middle">
                <Col span={24} className="titleNotify">
                  {WARNING_HISTORY_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "5" && (
                    <img className="iconNotify" src={RecoveryIcon} alt="logo" />
                  )}
                  {WARNING_HISTORY_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "1" && (
                    <img className="iconNotify" src={Warning1Icon} alt="logo" />
                  )}
                  {WARNING_HISTORY_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "2" && (
                    <img className="iconNotify" src={Warning2Icon} alt="logo" />
                  )}
                  {WARNING_HISTORY_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "3" && (
                    <img className="iconNotify" src={Warning3Icon} alt="logo" />
                  )}
                  {WARNING_HISTORY_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "4" && (
                    <img className="iconNotify" src={Warning4Icon} alt="logo" />
                  )}
                  {WARNING_HISTORY_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "6" && (
                    <span
                      style={{ paddingLeft: "25px", display: "inline-block" }}
                    ></span>
                  )}

                  {SYSTEM_WARNING_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "2" && (
                      <img className="iconNotify" src={Warning1Icon} alt="logo" />
                  )}
                  {SYSTEM_WARNING_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "3" && (
                      <img className="iconNotify" src={Warning2Icon} alt="logo" />
                  )}
                  {SYSTEM_WARNING_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "4" && (
                      <img className="iconNotify" src={Warning3Icon} alt="logo" />
                  )}
                  {SYSTEM_WARNING_DETAIL_NOTYFYS.indexOf(notifyList[i].notifyType) != -1 && notifyList[i].notifyLevel == "5" && (
                      <img className="iconNotify" src={Warning4Icon} alt="logo" />
                  )}

                  <Tooltip placement="topLeft" title={notifyList[i].title}>
                    <Paragraph className="notify-title" ellipsis>
                      {notifyList[i].title}
                    </Paragraph>
                  </Tooltip>
                  {/* {notifyList[i].notifyType == "5" && (
                    <span className="recovery">
                      {notifyList[i].typeContent}
                    </span>
                  )}
                  {notifyList[i].notifyType == "1" && (
                    <span className="warning1">
                      {notifyList[i].typeContent}
                    </span>
                  )}
                  {notifyList[i].notifyType == "2" && (
                    <span className="warning2">
                      {notifyList[i].typeContent}
                    </span>
                  )}
                  {notifyList[i].notifyType == "3" && (
                    <span className="warning3">
                      {notifyList[i].typeContent}
                    </span>
                  )}
                  {notifyList[i].notifyType == "4" && (
                    <span className="warning4">
                      {notifyList[i].typeContent}
                    </span>
                  )} */}
                </Col>
                <Col span={24} className="contentNotify">
                  <Tooltip placement="topLeft" title={notifyList[i].content}>
                    <Paragraph className="notify-content" ellipsis>
                      {notifyList[i].content}
                    </Paragraph>
                  </Tooltip>
                </Col>
                <Col span={24} className="timeNotify">
                  <Row className="rowNotMargin" justify="end">
                    日付: {moment(notifyList[i].createTime).format(DATE_FORMAT)}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        );
      }
    }

    return <>{notifyInfos}</>;
  };

  return (
    <Spin spinning={loading} tip="Loading...">
      <div className="scrollable-box" id="scrollableDiv" style={{ maxHeight: 400, overflow: "auto" }}>
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={notifyList ? notifyList.length : null}
          next={fetchMoreData}
          hasMore={hasMore}
        >
          {renderNotifyLayout()}
        </InfiniteScroll>
        {/* {renderNotifyLayout()} */}
      </div>
    </Spin>
  );
}

export default Notification;
