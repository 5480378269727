import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout, Row, Col, Popover, Button, Avatar, Badge, Select } from "antd";
import "./footer.scss";
import ROUTER_PAGE from "../../constants/router-constants";

const { Footer } = Layout;

function FooterRoot() {
  const { t } = useTranslation();
  return (
    <Footer className="site-layout-background">
      <Row align="middle">
        <Col span={12}>
          <span className="footerText">
            {t("AI流入水量予測システム（バージョン1.0）")}
          </span>
        </Col>
        <Col span={12}>
          <Row className="rowNotMargin" align="middle" justify="start">
            <Col flex="auto"></Col>
          </Row>
        </Col>
      </Row>
    </Footer>
  );
}

export default FooterRoot;
