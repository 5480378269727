export const STATUS = [
  {
    key: 1,
    value: "有効",
  },
  {
    key: 2,
    value: "無効",
  },
];

export const WARNING_TYPES = [
  {
    key: "2",
    value: "水位",
  },
  {
    key: "4",
    value: "流入水量実績",
  },
  {
    key: "1",
    value: "流入水量予測",
  },
  {
    key: "3",
    value: "流入水量予測精度検知",
  },
  {
    key: "5",
    value: "降水量予報",
  },
];

export const SIZE_BUTTON = {
  large: "large",
  medium: "medium",
  small: "small"
}

export const POSITION_ICON_BTN = {
  right: "right",
  left: "left"
}
const {userCode = ""} = JSON.parse(localStorage.getItem("userInfo") || "{}");
export const DASHBOARD_PAGE_ITEM_KEY = `${userCode}`;

export const ACTION_TYPES = [
  {
    key: "1",
    value: "新規",
  },
  {
    key: "2",
    value: "更新",
  },
  {
    key: "3",
    value: "削除",
  },
];

export const WARNING_OPERATORS = [">", ">=", "<", "<="];

export const DATE_FORMAT = "YYYY/MM/DD HH:mm:ss";
export const DATE_FORMAT_JAPAN = "YYYY年MM月DD日HH時";
export const DATE_JAPAN = "YYYY年MM月DD日";
export const HOUR_JAPAN = "HH時mm分";
export const DATE_TIME_FORMAT_JAPAN = "YYYY年MM月DD日HH時mm分";
export const DATE_TIME_FORMAT_JAPAN_2 = "YYYY年MM月DD日HH時mm分ss秒";
export const PAGE_SIZE = 10;

export const PAGE_SIZE_OPTION = [
  {
    key: 10,
    value: "10"
  }, 
  {
    key: 20,
    value: "20"
  }, {
    key: 50,
    value: "50"
  }, 
  {
    key: 100,
    value: "100"
  }
];
