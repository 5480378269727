import React, { useEffect } from "react";
import "./login.scss";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  authenticate,
  updateRole,
  setUserLogin,
} from "@src/redux/auth";

import { getLoggedUser } from "../../redux/modules/login/action";
import { setError } from "../../redux/error";
import { checkRequestRole } from "../../utils/checkRole";
import { getToken } from "../../redux/services/config";

function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    sessionStorage.setItem("notReload", true);
    const token = getToken();
    if (token) {
      dispatch(
        getLoggedUser({
          callback: function (res) {
            sessionStorage.removeItem("notReload");
            sessionStorage.removeItem("reloadCount");
            const { landingPage, userPermission } = res.data;
            dispatch(authenticate(true));
            dispatch(setUserLogin(res.data));
            dispatch(updateRole(JSON.stringify(userPermission)));
            localStorage.setItem("userInfo", JSON.stringify(res.data));
            let request = localStorage.getItem("currentUrl");
            if (request) {
              request = request
                  .replace(process.env.REACT_APP_CLIENT_ENDPOINT, "")
                  .replace("/#", "");
            } else {
              request = "/";
            }
            let userFactoryId = res.data.userFactoryId ? res.data.userFactoryId : "";
            localStorage.setItem("currentUrl", "/");
            if (request == "/" || request == "/login") {
              history.push(`${landingPage}/${userFactoryId}`);
            } else {
              if (!checkRequestRole(userPermission, request)) {
                history.push(`${landingPage}/${userFactoryId}`);
              } else {
                history.push(request);
              }
            }

            window.location.reload();
            return;
          },
          callbackFail: function (data) {
            sessionStorage.removeItem("notReload");
            sessionStorage.removeItem("reloadCount");
            dispatch(authenticate(false));
            dispatch(
              setError({
                message: data.message,
                callback: data.callback
              })
            );
            history.push("/error");
            return;
          },
        })
      );
    }
    // else {
    //   window.location.reload();
    // }
  }, []);

  return <div />;
}

export default LoginPage;
