import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  role: null,
  user: null,
  notify: null,
  listOfficeOwnUser: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticate: (state, { payload }) => {
      state.isLogin = payload;
    },
    updateRole: (state, { payload }) => {
      state.role = payload;
    },
    setUserLogin: (state, { payload }) => {
      state.user = payload;
    },
    setNotify: (state, { payload }) => {
      state.notify = payload;
    },
    setListOfficeOwnUser: (state, { payload }) => {
      state.listOfficeOwnUser = payload;
    },
  },
});

// the action
export const {
  authenticate,
  updateRole,
  setUserLogin,
  setNotify,
  setListOfficeOwnUser,
} = authSlice.actions;

//the selector
export const authSelector = (state) => state.auth;
export const selectorUser = (state) => state.auth.user;
export const selectorOfficeOwnUser = (state) => state.auth.listOfficeOwnUser;

// the reducer
export default authSlice.reducer;
