import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  getUsers,
  createOrUpdateUser,
  synceUsers,
  getRoles,
  createOrUpdateRole,
  getPermissons,
  getADUser,
} from "./action";

const initialState = {
  userList: {
    data: [],
    error: {},
    isLoading: false,
  },
  userAD: {
    data: {},
    error: {},
    isLoading: false,
  },
  roleList: {
    data: [],
    error: {},
    isLoading: false,
  },
  permissionList: {
    data: [],
    permissionRequiredMap: {},
    error: {},
    isLoading: false,
  },
  createOrUpdateUser: {
    data: [],
    error: {},
    isLoading: false,
  },
  createOrUpdateRole: {
    data: [],
    error: {},
    isLoading: false,
  },
  syncUser: {
    error: {},
    isLoading: false,
  },
};

const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    // get list users
    [getUsers.pending]: (state, action) => {
      state.userList.isLoading = true;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.userList.isLoading = false;
      state.userList.data = get(action, "payload.data", []);
    },
    [getUsers.rejected]: (state, action) => {
      state.userList.isLoading = false;
      state.userList.data = [];
      state.userList.error = action.payload;
    },
    // get AD user
    [getADUser.pending]: (state, action) => {
      state.userAD.isLoading = true;
    },
    [getADUser.fulfilled]: (state, action) => {
      state.userAD.isLoading = false;
      state.userAD.data = get(action, "payload.data", {});
    },
    [getADUser.rejected]: (state, action) => {
      state.userAD.isLoading = false;
      state.userAD.data = {};
      state.userAD.error = action.payload;
    },
    // create or update user
    [createOrUpdateUser.pending]: (state, action) => {
      state.createOrUpdateUser.isLoading = true;
    },
    [createOrUpdateUser.fulfilled]: (state, action) => {
      state.createOrUpdateUser.isLoading = false;
      state.createOrUpdateUser.data = action.payload;
      state.userAD.data = {};
    },
    [createOrUpdateUser.rejected]: (state, action) => {
      state.createOrUpdateUser.isLoading = false;
      state.createOrUpdateUser.data = [];
      state.createOrUpdateUser.error = action.payload;
    },

    // get list roles
    [getRoles.pending]: (state, action) => {
      state.roleList.isLoading = true;
    },
    [getRoles.fulfilled]: (state, action) => {
      state.roleList.isLoading = false;
      state.roleList.data = get(action, "payload.data", []);
    },
    [getRoles.rejected]: (state, action) => {
      state.roleList.isLoading = false;
      state.roleList.data = [];
      state.roleList.error = action.payload;
    },
    // create or update user
    [createOrUpdateRole.pending]: (state, action) => {
      state.createOrUpdateRole.isLoading = true;
    },
    [createOrUpdateRole.fulfilled]: (state, action) => {
      state.createOrUpdateRole.isLoading = false;
      state.createOrUpdateRole.data = action.payload;
    },
    [createOrUpdateRole.rejected]: (state, action) => {
      state.createOrUpdateRole.isLoading = false;
      state.createOrUpdateRole.data = [];
      state.createOrUpdateRole.error = action.payload;
    },
    // get list permissions
    [getPermissons.pending]: (state, action) => {
      state.permissionList.isLoading = true;
    },
    [getPermissons.fulfilled]: (state, action) => {
      state.permissionList.isLoading = false;
      state.permissionList.data = get(action, "payload.data.listTree", []);
      state.permissionList.permissionRequiredMap = get(
        action,
        "payload.data.permissionRequiredMap",
        []
      );
    },
    [getPermissons.rejected]: (state, action) => {
      state.permissionList.isLoading = false;
      state.permissionList.data = [];
      state.permissionList.error = action.payload;
    },
    // sync users
    [synceUsers.pending]: (state, action) => {
      state.syncUser.isLoading = true;
    },
    [synceUsers.fulfilled]: (state, action) => {
      state.syncUser.isLoading = false;
    },
    [synceUsers.rejected]: (state, action) => {
      state.syncUser.isLoading = false;
      state.syncUser.error = action.payload;
    },
  },
  reducers: {},
});

//the selector
export const userSelector = (state) => state.users;

// the reducer
export default userSlice.reducer;
