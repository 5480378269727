import { createAsyncThunk } from "@reduxjs/toolkit";
import notifyApi from "../../services/notify";
import {
  GET_NOTIFY,
  UPDATE_READ_NOTIFY,
  UPDATE_LIST_READ_NOTIFY,
} from "./type";

const getNotifyAsync = async (params, { dispatch }) => {
  const result = await notifyApi.getNotify(params.data);
  const notifyData = result.code === "0" ? result.data : {};
  notifyData.listNotify.forEach((notifyInfo, index) => {
    notifyInfo["key"] = index;
  });
  notifyData.isReadAll = params.isReadAll;
  notifyData.isGetNotifyInterval = params.isGetNotifyInterval;
  if (params.callback) {
    params.callback(result);
  }

  return notifyData;
};

const updateReadNotifyAsync = async (params, { dispatch }) => {
  const result = await notifyApi.updateReadNotify(params);
  return result;
};

const updateListReadNotifyAsync = async (params, { dispatch }) => {
  const result = await notifyApi.updateListReadNotify(params, dispatch);
  return result;
};

export const getNotify = createAsyncThunk(GET_NOTIFY, getNotifyAsync);

export const updateReadNotify = createAsyncThunk(
  UPDATE_READ_NOTIFY,
  updateReadNotifyAsync
);

export const updateListReadNotify = createAsyncThunk(
  UPDATE_LIST_READ_NOTIFY,
  updateListReadNotifyAsync
);
