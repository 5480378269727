import React, { useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import loadable from "@loadable/component";
import { Layout } from "antd";
import HeaderRoot from "@src/component/header/header.jsx";
import FooterRoot from "@src/component/footer/footer.jsx";
import DirectoryPage from "@src/component/directory/directory";
import SideBar from "@src/component/Sidebar/sideBar.jsx";
import { useSelector } from "react-redux";
import ROUTER_PAGE from "../constants/router-constants";
import { checkRole, checkListRole } from "../utils/checkRole";
import { ROLE_CONST } from "../constants/role-constants";
import { getDashboardPageObjId } from "../utils/dealCacheData";
import { selectorOfficeOwnUser } from "../redux/auth";

const WarningSettingPage = loadable(() =>
  import("../pages/office-management/warning-setting-page/warning-setting-page")
);
const WarningHistoryDetailPage = loadable(() =>
  import(
    "../pages/office-management/warning-history-detail-page/warning-history-detail-page"
  )
);
const SystemSettingPage = loadable(() =>
  import("../pages/system-management/system-setting-page/system-setting-page")
);
const DashboardSettingPage = loadable(() =>
  import(
    "../pages/office-management/dashboard-setting-page/dashboard-setting-page"
  )
);
const SystemWarningHistoryPage = loadable(() =>
  import(
    "../pages/system-management/system-warning-history-page/system-warning-history-page"
  )
);
const SystemWarningDetailPage = loadable(() =>
  import(
    "../pages/system-management/system-warning-detail-page/system-warning-detail-page"
  )
);
const WarningHistoryPage = loadable(() =>
  import("../pages/office-management/warning-history-page/warning-history-page")
);
const WarningEventPage = loadable(() =>
  import("../pages/office-management/warning-event-page/warning-event-page")
);
const DetectAbnormalPage = loadable(() =>
  import("../pages/office-management/detect-abnormal-page/detect-abnormal-page")
);
const DetectErrorPage = loadable(() =>
  import("../pages/office-management/detect-error-page/detect-error-page")
);
const OfficeSettingsPage = loadable(() =>
  import("../pages/office-management/office-settings-page/office-settings-page")
);
const DashboardPage = loadable(() =>
  import("../pages/office-management/dashboard-page/dashboard-page")
);
const DashboardOfficePage = loadable(() =>
  import(
    "../pages/office-management/dashboard-office-page/dashboard-office-page"
  )
);
const OfficeSettingPage = loadable(() =>
  import("../pages/system-management/office-setting-page/office-setting-page")
);
const FileStorageManagementPage = loadable(() =>
  import(
    "../pages/system-management/file-storage-mangement-page/file-storage-management-page"
  )
);
const UserManagementPage = loadable(() =>
  import("../pages/access-management/user-page/user-management-page")
);
const RoleManagementPage = loadable(() =>
  import("../pages/access-management/role-page/role-management-page")
);
const CameraSettingPage = loadable(() =>
  import("../pages/system-management/camera-setting-page/camera-setting-page")
);
const ProcessPlaceSettingPage = loadable(() =>
  import(
    "../pages/system-management/process-place-setting-page/process-place-setting-page"
  )
);
const SensorSettingPage = loadable(() =>
  import("../pages/system-management/sensor-setting-page/sensor-setting-page")
);

const ROUTER_WITH_ALL_OFFICE = [
  ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE,
  ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE,
]
const { Content } = Layout;
function RouterAuth() {
  const { collapsed } = useSelector((state) => state.sideBar);
  const { role, user } = useSelector((state) => state.auth);
  const history = useHistory();
  const officeListHeader = useSelector(selectorOfficeOwnUser) || [];
  const officeIdUrl =  history.location.pathname.match(/\-?\d+/g) ? Number( history.location.pathname.match(/\-?\d+/g)[0]) : null;
  const officeIdParam = officeIdUrl != -1 ? officeIdUrl: localStorage.getItem("officeSelected");
  const dashboardSavedStorerage = getDashboardPageObjId(officeIdParam);
  let pathname = history.location.pathname.replace(/\/?\-?\d+/g, "");
  if (pathname.includes("/null")) {
    pathname = pathname.slice(0, -5);
  }

  const officeIdFistOfArray = officeListHeader.length > 0 ? ((ROUTER_WITH_ALL_OFFICE.indexOf(pathname) != -1 && officeListHeader.length > 1) ? officeListHeader[1].value : officeListHeader[0].value) : officeIdParam; 
  const officeIdDefault = user.userFactoryId ?  user.userFactoryId : officeIdFistOfArray;
  const dashboardId = officeIdUrl == -1 ? (localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE) ? localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE) : officeIdDefault) : officeIdParam;
  const dashboardOfficeId = officeIdUrl == -1  ? (localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE) ? localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE) : officeIdDefault) : officeIdParam;
  const warningHistoryId = officeIdUrl == -1  ? (localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_PAGE) || officeIdDefault) : officeIdParam;
  const warningEventId = officeIdUrl == -1 ? (localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE) || officeIdDefault) : officeIdParam;
  const warningSettingId = officeIdUrl == -1  ? (localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE) || officeIdDefault) : officeIdParam;
  const dashboardSettingId = officeIdUrl == -1 ? (localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE) || officeIdDefault) : officeIdParam;
  const officeSettingId = officeIdUrl == -1 ? (localStorage.getItem(ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE) || officeIdDefault) : officeIdParam;
  const officeDasboardSelected = (dashboardSavedStorerage && dashboardSavedStorerage.officeId) ? dashboardSavedStorerage.officeId :  dashboardId;
   return (
    <Layout className="routerAuth">
      <SideBar />
      <Layout
        className="site-layout"
        style={collapsed ? { paddingLeft: "64px" } : { paddingLeft: "256px" }}
      >
        <HeaderRoot />
        <Content>
          <DirectoryPage />
          <Switch>
            {/*監視画面*/}
            {checkRole(role, ROLE_CONST.P600) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE}/${officeDasboardSelected}`} />
              </Route>
            )}
            {checkRole(role, ROLE_CONST.P600) && (
              <Route
                exact
                path={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE}/:officeIdParam`}
                component={DashboardPage}
              />
            )}

            {/*監視画面・事業所*/}
            {checkRole(role, ROLE_CONST.P600) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE}/${dashboardOfficeId}`} />
              </Route>
            )}
            {checkRole(role, ROLE_CONST.P600) && (
                <Route
                  exact
                  path={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE}/:officeIdParam`}
                  component={DashboardOfficePage}
                />
            )}

            {/*/!*警報履歴*!/*/}
            {/*{checkRole(role, ROLE_CONST.P500) && (*/}
            {/*  <Route*/}
            {/*    exact*/}
            {/*    path={*/}
            {/*      ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_PAGE*/}
            {/*    }*/}
            {/*    component={WarningHistoryPage}*/}
            {/*  />*/}
            {/*)}*/}

            {checkRole(role, ROLE_CONST.P500) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE}/${warningEventId}`} />
              </Route>
            )}

            {checkRole(role, ROLE_CONST.P500) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE}/:officeIdParam`
                }
                component={WarningEventPage}
              />
            )}

            {/*インポートデータ管理*/}
            {checkRole(role, ROLE_CONST.P202) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ABNORMAL_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ABNORMAL_PAGE}/${officeIdParam}`} />
              </Route>
            )}

            {checkRole(role, ROLE_CONST.P202) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ABNORMAL_PAGE}/:officeIdParam`
                }
                component={DetectAbnormalPage}
              />
            )}

            {/*警報設定*/}
            {checkListRole(role, [ROLE_CONST.P200, ROLE_CONST.P201]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE}/${warningSettingId}`} />
              </Route>
            )}

            {checkListRole(role, [ROLE_CONST.P200, ROLE_CONST.P201]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE}/:officeIdParam`
                }
                component={WarningSettingPage}
              />
            )}

            {/*画面構成設定*/}
            {checkRole(role, ROLE_CONST.P600) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE}/${dashboardSettingId}`} />
              </Route>
            )}
            {checkRole(role, ROLE_CONST.P600) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE}/:officeIdParam`
                }
                component={DashboardSettingPage}
              />
            )}

            {/*/!*精度誤差検知*!/*/}
            {/*{checkListRole(role, [ROLE_CONST.P203, ROLE_CONST.P204]) && (*/}
            {/*  <Route*/}
            {/*    exact*/}
            {/*    path={*/}
            {/*      ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ERROR_PAGE*/}
            {/*    }*/}
            {/*    component={DetectErrorPage}*/}
            {/*  />*/}
            {/*)}*/}

            {/*通知・表示設定画面*/}
            {checkRole(role, ROLE_CONST.P112) && (
             <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE}/${officeSettingId}`} />
              </Route>
            )}
            
            {checkRole(role, ROLE_CONST.P112) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE}/:officeIdParam`
                }
                component={OfficeSettingsPage}
              />
            )}

            {/*ユーザー*/}
            {checkListRole(role, [ROLE_CONST.P108, ROLE_CONST.P109]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE}/${officeIdParam}`} />
              </Route>
            )}

            {checkListRole(role, [ROLE_CONST.P108, ROLE_CONST.P109]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE}/:officeIdParam`
                }
                component={UserManagementPage}
              />
            )}

            {/*ロール*/}
            {checkListRole(role, [ROLE_CONST.P110, ROLE_CONST.P111]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE}/${officeIdParam}`} />
              </Route>
            )}

            {checkListRole(role, [ROLE_CONST.P110, ROLE_CONST.P111]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE}/:officeIdParam`
                }
                component={RoleManagementPage}
              />
            )}

            {/*事業所設定*/}
            {checkListRole(role, [ROLE_CONST.P102, ROLE_CONST.P103]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE}/${officeIdParam}`} />
              </Route>
            )}

            {checkListRole(role, [ROLE_CONST.P102, ROLE_CONST.P103]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE}/:officeIdParam`
                }
                component={OfficeSettingPage}
              />
            )}

            {/*システム設定*/}
            {checkRole(role, ROLE_CONST.P101) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE}/${officeIdParam}`} />
              </Route>
            )}
            {checkRole(role, ROLE_CONST.P101) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE}/:officeIdParam`
                }
                component={SystemSettingPage}
              />
            )}

            {/*ストレージデータ監視*/}
            {checkListRole(role, [ROLE_CONST.P302]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE}/${officeIdParam}`} />
              </Route>
            )}
            {checkListRole(role, [ROLE_CONST.P302]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE}/:officeIdParam`
                }
                component={FileStorageManagementPage}
              />
            )}

            {/*カメラ設定*/}
            {checkListRole(role, [ROLE_CONST.P106, ROLE_CONST.P107]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE}/${officeIdParam}`} />
              </Route>
            )}
            {checkListRole(role, [ROLE_CONST.P106, ROLE_CONST.P107]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE}/:officeIdParam`
                }
                component={CameraSettingPage}
              />
            )}

            {/*システム監視*/}
            {checkRole(role, ROLE_CONST.P300) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE}/${warningHistoryId}`} />
              </Route>
            )}
            {checkRole(role, ROLE_CONST.P300) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE}/:officeIdParam`
                }
                component={SystemWarningHistoryPage}
              />
            )}

            {/*施設設定*/}
            {checkListRole(role, [ROLE_CONST.P104, ROLE_CONST.P105]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE}/${officeIdParam}`} />
              </Route>
            )}

            {checkListRole(role, [ROLE_CONST.P104, ROLE_CONST.P105]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE}/:officeIdParam`
                }
                component={ProcessPlaceSettingPage}
              />
            )}

            {/*センサー設定*/}
            {checkListRole(role, [ROLE_CONST.P104, ROLE_CONST.P105]) && (
              <Route
                exact
                path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE}>
                  <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE}/${officeIdParam}`} />
              </Route>
            )}

            {checkListRole(role, [ROLE_CONST.P104, ROLE_CONST.P105]) && (
              <Route
                exact
                path={
                  `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE}/:officeIdParam`
                }
                component={SensorSettingPage}
              />
            )}

            <Route
              exact
              path={ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE}>
                <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE}/${officeIdParam}`} />
            </Route>
            <Route
              exact
              path={
                `${ROUTER_PAGE.ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE}/:officeIdParam`
              }
              component={WarningHistoryDetailPage}
            />

            <Route
              exact
              path={ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE}>
                <Redirect to={`${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE}/${officeIdParam}`} />
            </Route>
            <Route
              exact
              path={
                `${ROUTER_PAGE.ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE}/:officeIdParam`
              }
              component={SystemWarningDetailPage}
            />

            <Route path="*">
              <Redirect to={ROUTER_PAGE.ROUTER_PAGE.ERROR_PAGE} />
            </Route>
          </Switch>
        </Content>
        <FooterRoot />
      </Layout>
    </Layout>
  );
}

export default RouterAuth;
