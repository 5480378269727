import { ROUTER_PAGE_MAPPING, ROUTER_PAGE } from "./router-constants";

export const ROUTERS_ONLY_OPTION_ALL = [
  ROUTER_PAGE.ACCESS_MANAGEMENT.ROLE_MANAGEMENT_PAGE,
  ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_HISTORY_PAGE,
  ROUTER_PAGE.SYSTEM_MANAGEMENT.FILE_STORAGE_PAGE,
  ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_SETTING_PAGE,
];

export const ROUTERS_HAVE_OPTION_ALL = [
  ROUTER_PAGE.ACCESS_MANAGEMENT.USER_MANAGEMENT_PAGE,
  ROUTER_PAGE.SYSTEM_MANAGEMENT.OFFICE_SETTING_PAGE,
  ROUTER_PAGE.SYSTEM_MANAGEMENT.PROCESS_PLACE_SETTING_PAGE,
  ROUTER_PAGE.SYSTEM_MANAGEMENT.SENSOR_SETTING_PAGE,
  ROUTER_PAGE.SYSTEM_MANAGEMENT.CAMERA_SETTING_PAGE,
];

export const ROUTERS_NO_OPTION_ALL = [
  ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_OFFICE_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_EVENT_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_SETTING_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.DASHBOARD_SETTING_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.OFFICE_SETTINGS_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.DETECT_ERROR_PAGE,
];

export const ROUTERS_DISABLE_OFFICE = [
  ROUTER_PAGE.SYSTEM_MANAGEMENT.SYSTEM_WARNING_DETAIL_PAGE,
  ROUTER_PAGE.OFFICE_MANAGEMENT.WARNING_HISTORY_DETAIL_PAGE,
];
