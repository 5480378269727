import React from "react";
import { useSelector } from "react-redux";

import { Spin } from "antd";

function AppLoading() {
  const { isLoading } = useSelector((state) => state.loading);
  if (!isLoading) return null;
  return (
    <div className="loading-app">
      <Spin tip="Loading..." size="large" spinning={isLoading}></Spin>
    </div>
  );
}

export default AppLoading;
