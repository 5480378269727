import { combineReducers } from "redux";
import loadingReducer from "./loading";
import sideBarReducer from "./sideBar";
import authReducer from "./auth";
import errorReducer from "./error";
import systemReducer from "./modules/system/reducer";
import officeReducer from "./modules/offices/reducer";

import userReducer from "./modules/users/reducer";
import configReducer from "./modules/configs/reducer";
import cameraReducer from "./modules/cameras/reducer";
import warningReducer from "./modules/warnings/reducer";
import notifyReducer from "./modules/notification/reducer";
import logReducer from "./modules/logs/reducer";
import dashboardReducer from "./modules/dashboards/reducer";

const rootReducer = combineReducers({
  loading: loadingReducer,
  sideBar: sideBarReducer,
  auth: authReducer,
  error: errorReducer,
  system: systemReducer,
  users: userReducer,
  configs: configReducer,
  cameras: cameraReducer,
  warnings: warningReducer,
  notify: notifyReducer,
  logs: logReducer,
  offices: officeReducer,
  dashboards: dashboardReducer,
});

export default rootReducer;
