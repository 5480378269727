import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  getWarnings,
  getDashboardWarnEvents,
  getDashboardInfo,
  getWarningConfigs,
  createOrUpdateWarning,
  getListOperationGuidanceManual,
  updateOperationGuidanceManual
} from "./action";

const initialState = {
  warningList: {
    data: [],
    error: {},
    isLoading: false,
  },
  dweList: {
    data: [],
    error: {},
    isLoading: false,
  },
  wcConfigs: {
    data: {},
    error: {},
    isLoading: false,
  },
  dashboardInfo: {
    data: {},
    error: {},
    isLoading: false,
  },
  createOrUpdateWarning: {
    data: [],
    error: {},
    isLoading: false,
  },
  operationGuidanceManualList: {
    data: [],
    error: {},
    isLoading: false,
  },
  updateOperationGuidanceManual: {
    data: [],
    error: {},
    isLoading: false,
  },
};

const warningSlice = createSlice({
  name: "warnings",
  initialState,
  extraReducers: {
    // get list warnings
    [getWarnings.pending]: (state, action) => {
      state.warningList.isLoading = true;
    },
    [getWarnings.fulfilled]: (state, action) => {
      state.warningList.isLoading = false;
      state.warningList.data = get(action, "payload.data", []);
    },
    [getWarnings.rejected]: (state, action) => {
      state.warningList.isLoading = false;
      state.warningList.data = [];
      state.warningList.error = action.payload;
    },
    // get list warning configs
    [getWarningConfigs.pending]: (state, action) => {
      state.wcConfigs.isLoading = true;
    },
    [getWarningConfigs.fulfilled]: (state, action) => {
      state.wcConfigs.isLoading = false;
      state.wcConfigs.data = get(action, "payload.data", {});
    },
    [getWarningConfigs.rejected]: (state, action) => {
      state.wcConfigs.isLoading = false;
      state.wcConfigs.data = {};
      state.wcConfigs.error = action.payload;
    },
    // get list dashboard warn events
    [getDashboardWarnEvents.pending]: (state, action) => {
      state.dweList.isLoading = true;
    },
    [getDashboardWarnEvents.fulfilled]: (state, action) => {
      state.dweList.isLoading = false;
      state.dweList.data = get(action, "payload.data", []);
    },
    [getDashboardWarnEvents.rejected]: (state, action) => {
      state.dweList.isLoading = false;
      state.dweList.data = [];
      state.dweList.error = action.payload;
    },
    // get list dashboard info
    [getDashboardInfo.pending]: (state, action) => {
      state.dashboardInfo.isLoading = true;
    },
    [getDashboardInfo.fulfilled]: (state, action) => {
      state.dashboardInfo.isLoading = false;
      state.dashboardInfo.data = get(action, "payload.data", {});
    },
    [getDashboardInfo.rejected]: (state, action) => {
      state.dashboardInfo.isLoading = false;
      state.dashboardInfo.data = {};
      state.dashboardInfo.error = action.payload;
    },
    // create or update warning
    [createOrUpdateWarning.pending]: (state, action) => {
      state.createOrUpdateWarning.isLoading = true;
    },
    [createOrUpdateWarning.fulfilled]: (state, action) => {
      state.createOrUpdateWarning.isLoading = false;
      state.createOrUpdateWarning.data = action.payload;
    },
    [createOrUpdateWarning.rejected]: (state, action) => {
      state.createOrUpdateWarning.isLoading = false;
      state.createOrUpdateWarning.data = [];
      state.createOrUpdateWarning.error = action.payload;
    },
    // Get list operation guidance manual
    [getListOperationGuidanceManual.pending]: (state, action) => {
      state.operationGuidanceManualList.isLoading = true;
    },
    [getListOperationGuidanceManual.fulfilled]: (state, action) => {
      state.operationGuidanceManualList.isLoading = false;
      state.operationGuidanceManualList.data = action.payload;
    },
    [getListOperationGuidanceManual.rejected]: (state, action) => {
      state.operationGuidanceManualList.isLoading = false;
      state.operationGuidanceManualList.data = [];
      state.operationGuidanceManualList.error = action.payload;
    },
    // update operation guidance manual
    [updateOperationGuidanceManual.pending]: (state, action) => {
      state.updateOperationGuidanceManual.isLoading = true;
    },
    [updateOperationGuidanceManual.fulfilled]: (state, action) => {
      state.updateOperationGuidanceManual.isLoading = false;
      state.updateOperationGuidanceManual.data = action.payload;
    },
    [updateOperationGuidanceManual.rejected]: (state, action) => {
      state.updateOperationGuidanceManual.isLoading = false;
      state.updateOperationGuidanceManual.data = [];
      state.updateOperationGuidanceManual.error = action.payload;
    },
  },
  reducers: {},
});

//the selector
export const warningSelector = (state) => state.warnings;

// the reducer
export default warningSlice.reducer;
