import { createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import {
  getHighchartsData,
  getDeviceDataRealtime,
  getDeviceDataRealtimeTable,
  uploadOperationGuidanceManualFile,
  deleteOperationGuidanceManualFile,
} from "./action";

const initialState = {
  highChartDataList: {
    data: [],
    error: {},
    isLoading: false,
  },
  highChartRealtimeList: {
    data: { listSeries: [] },
    error: {},
    isLoading: false,
  },
  realtimeList: {
    data: [],
    error: {},
    isLoading: false,
  },
  uploadOperationGuidanceManualFile: {
    data: [],
    error: {},
    isLoading: false,
  },
  deleteOperationGuidanceManualFile: {
    data: [],
    error: {},
    isLoading: false,
  },
};

const dashboardSlice = createSlice({
  name: "dashboards",
  initialState,
  extraReducers: {
    // get list highcharts info
    [getHighchartsData.pending]: (state, action) => {
      state.highChartDataList.isLoading = true;
    },
    [getHighchartsData.fulfilled]: (state, action) => {
      state.highChartDataList.isLoading = false;
      state.highChartDataList.data = get(action, "payload.data", {});
    },
    [getHighchartsData.rejected]: (state, action) => {
      state.highChartDataList.isLoading = false;
      state.highChartDataList.data = {};
      state.highChartDataList.error = action.payload;
    },
    // get list highcharts realtime info
    [getDeviceDataRealtime.pending]: (state, action) => {
      state.highChartRealtimeList.isLoading = true;
    },
    [getDeviceDataRealtime.fulfilled]: (state, action) => {
      state.highChartRealtimeList.isLoading = false;
      state.highChartRealtimeList.data = get(action, "payload.data", {
        listSeries: [],
      });
    },
    [getDeviceDataRealtime.rejected]: (state, action) => {
      state.highChartRealtimeList.isLoading = false;
      state.highChartRealtimeList.data = { listSeries: [] };
      state.highChartRealtimeList.error = action.payload;
    },
    // get list realtime info
    [getDeviceDataRealtimeTable.pending]: (state, action) => {
      state.realtimeList.isLoading = true;
    },
    [getDeviceDataRealtimeTable.fulfilled]: (state, action) => {
      state.realtimeList.isLoading = false;
      state.realtimeList.data = get(action, "payload.data", []);
    },
    [getDeviceDataRealtimeTable.rejected]: (state, action) => {
      state.realtimeList.isLoading = false;
      state.realtimeList.data = [];
      state.realtimeList.error = action.payload;
    },
    // upload operation guidance manual file
    [uploadOperationGuidanceManualFile.pending]: (state, action) => {
      state.uploadOperationGuidanceManualFile.isLoading = true;
    },
    [uploadOperationGuidanceManualFile.fulfilled]: (state, action) => {
      state.uploadOperationGuidanceManualFile.isLoading = false;
      state.uploadOperationGuidanceManualFile.data = action.payload;
    },
    [uploadOperationGuidanceManualFile.rejected]: (state, action) => {
      state.uploadOperationGuidanceManualFile.isLoading = false;
      state.uploadOperationGuidanceManualFile.data = [];
      state.uploadOperationGuidanceManualFile.error = action.payload;
    },
    // delete operation guidance manual file
    [deleteOperationGuidanceManualFile.pending]: (state, action) => {
      state.deleteOperationGuidanceManualFile.isLoading = true;
    },
    [deleteOperationGuidanceManualFile.fulfilled]: (state, action) => {
      state.deleteOperationGuidanceManualFile.isLoading = false;
      state.deleteOperationGuidanceManualFile.data = action.payload;
    },
    [deleteOperationGuidanceManualFile.rejected]: (state, action) => {
      state.deleteOperationGuidanceManualFile.isLoading = false;
      state.deleteOperationGuidanceManualFile.data = [];
      state.deleteOperationGuidanceManualFile.error = action.payload;
    },
  },
  reducers: {},
});

//the selector
export const selectorHighChartRealtimeList = (state) =>
  state.dashboards.highChartRealtimeList;

  export const selectorRealtimeList = (state) =>
  state.dashboards.realtimeList;

export const selectorHighChartDataList = (state) =>
  state.dashboards.highChartDataList.data;

// the reducer
export default dashboardSlice.reducer;
