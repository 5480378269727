import React, {forwardRef } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import "./button.scss";
import {SIZE_BUTTON, POSITION_ICON_BTN} from "../../constants/common"


function ButtonComponent(props, ref) {
  const { t } = useTranslation();
  let {
    className,
    type,
    handleClick,
    label,
    icon,
    size,
    htmlType = "button",
    disabled = false,
    block = false,
    positionIcon= null,
    style = {}
  } = props;
  let sizeBtn = {};
  switch(size) {
      case SIZE_BUTTON.medium: {
        sizeBtn = {minWidth: "88px", height: "32px"}
        if (icon) {
            sizeBtn["minWidth"] = "106px";
        }
        style = {...style, ...sizeBtn};
        break;
      }
      case SIZE_BUTTON.small: {
        sizeBtn = {minWidth: "72px", height: "32px"}
        if (icon) {
            sizeBtn["minWidth"] = "90px";
        }
        style = {...style, ...sizeBtn};
        break;
      }
      case SIZE_BUTTON.large: {
        sizeBtn = {minWidth: "102px", height: "32px"};
        style = {...style, ...sizeBtn};
        break;
      }
  }
  const  renderContentBtn =  () => {
    switch(positionIcon) {
        case POSITION_ICON_BTN.left: {
            return (
                <>
                    {icon}<span>{label}</span>
                </>
            )
            break;
        }
        case POSITION_ICON_BTN.right: {
            return (
                <>
                    <span>{label}</span>{icon}
                </>
            )
            break;
        }
        default:
            return label;
            break;
    }
  }
  
  return (
    <Button 
        ref={ref}
        className={`btn ${className}`} 
        onClick={() => handleClick()}
        style={style}
        disabled={disabled}
        htmlType={htmlType}
        block={block}
    >
      {renderContentBtn()}
    </Button>
  );
}

export default forwardRef(ButtonComponent);
