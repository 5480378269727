import React from "react";
import ReactDOM from "react-dom";
// import "@src/styles/fonts/stylesheet.css";
import "./i18n/i18n";
import "./index.css";

import { ConfigProvider } from "antd";
import jaJP from "antd/lib/locale/ja_JP";

import App from "./App";
import "antd/dist/antd.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "@src/redux/store";

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={jaJP}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
