import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: {
    message: "",
    type: "",
  },
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.error = payload;
    },
  },
});

// the action
export const { setError } = errorSlice.actions;

//the selector
export const errorSelector = (state) => state.error;

// the reducer
export default errorSlice.reducer;
