import apiClient from "./api";
const notifyApi = {
  // define call api for notify
  getNotify: async (data, dispatch) => {
    try {
      const url = "/notification/search";
      const response = await apiClient.post(url, data);
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      console.log("error", error);
    }
  },
  updateReadNotify: async (params, dispatch) => {
    try {
      const { data, callback } = params;
      const url = "/notification/updateRead";
      const response = await apiClient.post(url, data);
      if (response && response.data) {
        if (callback) {
          callback(response);
        }
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },
  updateListReadNotify: async (params, dispatch) => {
    try {
      const url = "/notification/updateReadAllByUser";
      const response = await apiClient.post(url);
      if (response && response.data) {
        if (params.callback) {
          params.callback();
        }
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },
};

export default notifyApi;
